import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modal";
import { modal, error } from "../../state/reducers/r_main";
import { Submit, TextField } from "../components/forms";
import { Error } from "../components/alerts";
import { deleteRecord } from "../../state/actions/_roles";

export default function Delete({ ind }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const main = useSelector((state) => state.main);
  const role = useSelector((state) => state.role);
  const [data, setData] = useState();

  const close = () => {
    dispatch(modal());
    dispatch(error());
    navigate("/roles");
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setData(role.roles[ind]);
  }, [ind]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(deleteRecord(data));
  };

  return (
    data && (
      <Modal size="w-1/2" title="Delete Role" state={main.modal} close={close}>
        <div className="w-3/4 mx-auto mb-2">
          <Error
            state={main.error.status}
            message={main.error.message}
            close={() => dispatch(error())}
          />
        </div>
        <form
          action=""
          className="w-3/4 mx-auto p-5 bg-gray-100 shadow"
          onSubmit={handleSubmit}
        >
          <TextField
            type="text"
            id="name"
            value={data.name}
            disabled={true}
            onChange={handleChange}
            label="Name"
            error={main.error}
          />
          <div className="flex justify-center mt-5">
            <Submit
              text="Delete Role"
              icon="fa-solid fa-trash"
              state={main.btnLoading}
            />
          </div>
        </form>
      </Modal>
    )
  );
}
