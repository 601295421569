export default function Modal({ size, title, state, close, children }) {
  return (
    state && (
      <>
        <div className="bg-black opacity-50 z-10 h-screen w-screen fixed top-0 left-0 print:hidden"></div>
        <div className="fixed h-screen w-screen z-20 overflow-y-scroll top-0 left-0 flex justify-center print:hidden">
          <div
            className={`${
              size ? size : "w-1/2"
            } fixed bg-white top-24 rounded h-auto`}
          >
            <h2 className="font-bold p-2 border-b border-gray-300 flex justify-between items-center">
              <span>{title}</span>
              {close && <i className="fa-solid fa-times cursor-pointer hover:scale-110" onClick={close} />}
            </h2>
            <div className="pt-10 pb-10 px-5">{children}</div>
          </div>
        </div>
      </>
    )
  );
}
