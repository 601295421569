// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Modal from "../components/modal";
import { useEffect } from "react";

export default function Printing({ total }) {
  const check = useSelector((state) => state.check);
  // const navigate = useNavigate();

  const handleUserKeyPress = (event) => {
    const { key, keyCode } = event;
    if (keyCode === 13 || keyCode === 27) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  return (
    <Modal
      size="w-1/5"
      title="Printing Check"
      state={check.receipt_print}
      close={false}
    >
      {check.receipt_printing_done ? (
        <div className="flex flex-col justify-center items-center space-y-3">
          <div className="flex items-center space-x-2 mb-5">
            <i className="fa-solid fa-check-circle text-green-700 fa-3x" />
            <div className="font-bold text-4xl text-green-700 border p-2 rounded-lg border-green-500">
              ${total}
            </div>
          </div>
          <button
            className="font-bold text-green-800 text-center text-2xl bg-green-300 border-b-4 border-green-700 px-5 rounded active:scale-95"
            onClick={() => window.location.reload()}
          >
            Aceptar
          </button>
          <button
            className="font-bold text-yellow-800 text-center text-2xl bg-yellow-300 border-b-4 border-yellow-700 px-5 rounded active:scale-95"
            onClick={window.print}
          >
            Imprimir Copia
          </button>
        </div>
      ) : (
        <>
          <Loading />
          <div className="font-bold text-green-800 text-center">
            Printing Receipt...
          </div>
        </>
      )}
    </Modal>
  );
}
