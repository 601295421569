/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../components/modal";
import { error } from "../../state/reducers/r_main";
import { Submit, TextField } from "../components/forms";
import { Error } from "../components/alerts";
import { storeRecord } from "../../state/actions/_whitelist";
import { useEffect } from "react";

export default function CreateFromSelector({ modal, close }) {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const [data, setData] = useState({
    name: "",
  });
  const [step, setStep] = useState(0);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(storeRecord(data, success));
  };

  const success = () => {
    setStep(1);
  };

  useEffect(() => {
    if (step === 1) {
      close();
      setStep(0);
    }
  }, [step]);

  return (
    <Modal size="w-1/2" title="Add Item" state={modal} close={close}>
      <div className="w-3/4 mx-auto mb-2">
        <Error
          state={main.error.status}
          message={main.error.message}
          close={() => dispatch(error())}
        />
      </div>
      <form onSubmit={handleSubmit} className="w-3/4 mx-auto p-5 bg-gray-100 shadow">
        <TextField
          type="text"
          id="name"
          value=""
          disabled={false}
          onChange={handleChange}
          label="Name"
          error={main.error}
        />
        <div className="flex justify-center mt-5">
          <Submit
            text="Add"
            icon="fa-solid fa-plus"
            state={main.btnLoading}
          />
        </div>
      </form>
    </Modal>
  );
}
