import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMenu, logoutAction, getUser } from "../../../state/actions/_auth";

const style = {
  link: "py-1 px-2 border-b-2 border-blue-800 hover:border-b-2 hover:border-blue-600 flex justify-center items-center rounded text-white active:scale-95 hover:scale-105",
  link_sub: "py-1 px-2 border-b-2 border-blue-800 hover:border-b-2 hover:border-blue-600 flex justify-center items-center rounded-t text-white cursor-pointer",
};

export default function Menu() {
  const dispatch = useDispatch();
  const r_auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMenu());
    dispatch(getUser());
  }, [dispatch, r_auth.auth]);

  // console.log(r_auth)

  return (
    <div>
      <ul className="flex justify-end space-x-2  main_menu">
        <li>
          <Link
            to=""
            className={`${style.link} bg-gradient-to-b from-blue-500 to-blue-700`}
          >
            <i className="fa-solid fa-home mr-1"></i> Inicio
          </Link>
        </li>
        {r_auth.auth ? (
          <>
            {r_auth.permissions &&
              r_auth.permissions.length > 0 &&
              r_auth.permissions.map((item, key) =>
                item.length > 1 ? (
                  <li key={key}>
                    <div
                      className={`${style.link_sub} ${
                        item[0].module === "dispatch"
                          ? "bg-gradient-to-b from-green-500 to-green-700"
                          : "bg-gradient-to-b from-blue-500 to-blue-700"
                      }`}
                    >
                      <i className={`${item[0].icon} mr-1`}></i> {item[0].name}
                      <ul className="sub_menu">
                        {item[1].map( (sm, sm_key) =>
                          <li key={sm_key}>
                            <Link to={sm.url}>
                              <i className={`${sm.icon} mr-1`}></i> {sm.name}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li key={key}>
                    <Link
                      to={item[0].url}
                      className={`${style.link} ${
                        item[0].module === "dispatch"
                          ? "bg-gradient-to-b from-green-500 to-green-700"
                          : "bg-gradient-to-b from-blue-500 to-blue-700"
                      }`}
                    >
                      <i className={`${item[0].icon} mr-1`}></i> {item[0].name}
                    </Link>
                  </li>
                )
              )}
            <li>
              <span
                onClick={() => dispatch(logoutAction())}
                className={`${style.link} cursor-pointer bg-gradient-to-b from-blue-500 to-blue-700`}
              >
                <i className="fa-solid fa-sign-out-alt mr-1"></i> Salir
              </span>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link
                to="/login"
                className={`${style.link} bg-gradient-to-b from-blue-500 to-blue-700`}
              >
                <i className="fa-solid fa-sign-in-alt mr-1"></i> Entrar
              </Link>
            </li>
          </>
        )}
      </ul>
      {r_auth.profile && r_auth.profile?.name && (
        <div className="flex justify-end pt-2">
          <span className="font-bold ml-1">{r_auth.profile.name}</span>.
          <span className="font-bold mx-1">/ {r_auth.profile.role.name}</span>
          {" - "}
          <span className="font-bold mx-1">{r_auth.profile.cashier.name}</span>
        </div>
      )}
    </div>
  );
}
