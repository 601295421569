import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modal";
import { modal, error } from "../../state/reducers/r_main";
import { Submit, TextField } from "../components/forms";
import { Error } from "../components/alerts";
import { updateRecord } from "../../state/actions/_config";

export default function Update({ ind }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);
  const [data, setData] = useState();

  const close = () => {
    dispatch(modal());
    dispatch(error());
    navigate("/config");
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setData(config.config[ind]);
  }, [ind]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateRecord(data));
  };

  return (
    data && (
      <Modal size="w-1/2" title="Update Role" state={main.modal} close={close}>
        <div className="w-3/4 mx-auto mb-2">
          <Error
            state={main.error.status}
            message={main.error.message}
            close={() => dispatch(error())}
          />
        </div>
        <form
          action=""
          className="w-3/4 mx-auto p-5 bg-gray-100 shadow"
          onSubmit={handleSubmit}
        >
          <TextField
            type="text"
            id="value"
            value={data.value}
            disabled={false}
            onChange={handleChange}
            label="Value"
            error={main.error}
          />
          
          <div className="flex justify-center mt-5">
            <Submit
              text="Update Role"
              icon="fa-solid fa-edit"
              state={main.btnLoading}
            />
          </div>
        </form>
      </Modal>
    )
  );
}
