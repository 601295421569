import api from "../../utils/api";
import { auth, btnLoading, permissions, profile } from "../reducers/r_auth";
import { error } from "../reducers/r_main";

export const verifyLogin = () => async (dispatch) => {
  try {
    await api.get("/verify-login");
    dispatch(auth(true));
  } catch (err) {
    dispatch(auth(false));
  }
};

export const getMenu = () => async (dispatch) => {
  try {
    const response = await api.get("/permission");
    dispatch(permissions(response.data));
  } catch (err) {
    console.error(err);
  }
};

export const getUser = () => async dispatch => {
  try {
    const response = await api.get('/get-profile');
    dispatch(profile(response.data[0]));
  } catch (err) {
    console.error(err)
  }
}

export const loginAction = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    const select_cashier = await api.post("/select-cashier", data);
    if (select_cashier.status === 200) {
      try {
        const info = {
          grant_type: "password",
          client_id: process.env.REACT_APP_CLIENT,
          client_secret: process.env.REACT_APP_SECRET,
          username: data.username,
          password: data.password,
          scope: "",
        };
        const response = await api.post(
          `${process.env.REACT_APP_URL}/oauth/token`,
          info
        );
        if (response.status === 200) {
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("expires_in", response.data.expires_in);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("token_type", response.data.token_type);
          setCashierToUser(dispatch, data.cashier_id, response);
        }
      } catch (err) {
        // console.log(err.response.data);
        dispatch(
          error({
            status: true,
            message: err.response.data.message,
            errors: err.response.data.errors,
          })
        );
      }
    }
  } catch (err) {
    // console.log("Error ", err);
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading(false));
  }
};

async function setCashierToUser(dispatch, cashier_id, response) {
  try {
    const headers = {
      headers: {
        Authorization: `${response.data.token_type} ${response.data.access_token}`
      }
    }
    await api.get(`/assign-cashier/${cashier_id}`, headers);
    dispatch(auth(true));
    window.location.href="/dispatch";
  } catch (err) {
    console.error(err);
  }
}

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_type");
  dispatch(auth(false));
  window.location.href = "/login";
};
