import { createSlice } from "@reduxjs/toolkit";

const cashierSlice = createSlice({
	name: 'role',
	initialState: {
		cashier: [],
		loaded: false,
	},
	reducers: {
		cashier: (state, action) => {
			state.cashier = action.payload;
			state.loaded = true;
		},
		loaded: (state, action) => {
			state.loaded = action.payload ? action.payload : false
		},
	}
})

export const { cashier, loaded } = cashierSlice.actions;
export default cashierSlice.reducer;