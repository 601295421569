import api from '../../utils/api';
import { loading, btnLoading, error, success, modal } from '../reducers/r_main';
import { roles, loaded, roleLoading, permissions } from '../reducers/r_roles';

export const getRecords = () => async (dispatch) => {
	dispatch(loading(true));
	dispatch(error());
	try {
		const response = await api.get('role');
		dispatch(roles(response.data));
		dispatch(error());
	} catch (err) {
		console.error(err.response.data);
	} finally {
		dispatch(loading());
	}
}

export const storeRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.post(`role`, data);
		dispatch(loaded());
		dispatch(modal());
		// dispatch(success({
		// 	status: true,
		// 	message: 'User stored successfully.'
		// }))
	} catch (err) {	
		dispatch(error({
			status: true,
			message: err.response.data.message,
			errors: err.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}

export const updateRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.put(`role/${data.id}`, data);
		dispatch(loaded());
		dispatch(modal());
		// dispatch(success({
		// 	status: true,
		// 	message: 'User updated successfully.'
		// }))
	} catch (err) {	
		dispatch(error({
			status: true,
			message: err.response.data.message,
			errors: err.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}

export const deleteRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.delete(`role/${data.id}`, data);
		dispatch(loaded());
		dispatch(modal())
		// dispatch(success({
		// 	status: true,
		// 	message: 'User deleted successfully.'
		// }))
	} catch (error) {	
		dispatch(error({
			status: true,
			message: error.response.data.message,
			errors: error.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}

export const getPermissions = (role_id) => async (dispatch) => {
	dispatch(roleLoading(true));
	try {
		const response = await api.get(`permission/${role_id}`);
		dispatch(permissions(response.data))
	} catch (error) {
		console.error(error.response.data)
	} finally {
		dispatch(roleLoading());
	}
}

export const updatePermission = (value, module, role) => async (dispatch) => {
	try {
		let data = {
			value: value,
			module_id: module,
			role_id: role
		}
		await api.post(`permission`, data);
	} catch (error) {
		console.error(error.response.data)
	}
}