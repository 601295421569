/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Submit, SubmitAction } from "../components/forms";
// import { Info } from "../components/alerts";
import Loading from "../components/Loading";
import { createCheck } from "../../state/actions/_checks";
import Report from "./report";
import Printing from "./printing";
import Selector from "../whitelist/selector";
import CreateFromSelector from "../whitelist/create-from-selector";
import CurrencyInput from "react-currency-input-field";

export default function Form({ checkData, withdrawalModal }) {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);
  const [data, setData] = useState({
    gross: 0,
    taxes: 0,
    status: "pending",
    check_num: checkData?.check_num || null,
  });
  const [percentage, setPercentage] = useState();
  const [modalAddClient, setModalAddClient] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    let result = config.config.filter((item) => item.code == "percentage");
    setPercentage(result[0].value);
    setData({
      ...data,
      company_fee: result[0].value,
    });
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (data.gross && data.taxes) {
      setData({
        ...data,
        net: (data.gross - data.taxes).toFixed(2),
      });
    }
  }, [data.gross, data.taxes]);

  useEffect(() => {
    if (data.net) {
      setData({
        ...data,
        total_fee: (data.net * (percentage / 100)).toFixed(2),
        total_to_pay: (data.net - data.net * (percentage / 100)).toFixed(2),
      });
    }
  }, [data.net]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createCheck(data));
  };

  const changeSelector = (event) => {
    setData({
      ...data,
      whitelist: event.value,
      company_ticket: event.label,
      company_color: event.color
    });
  };

  const handleUserKeyPress = event => {
    const { key, keyCode } = event;
    if (keyCode === 123) {
      alternativePrint()
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const alternativePrint = () => {
    dispatch(createCheck(data));
  }
  return (
    <>
      <Printing total={data.total_to_pay} />

      <div className="">
        {/* <div className="print:hidden">
          <Info state={true} message="Check number does not exist" />
        </div> */}
        {!percentage ? (
          <Loading />
        ) : (
          <div className="flex justify-between mb-96 relative" id="form-check">
            <form
              action=""
              className="w-4/12 mx-auto mt-10 print:hidden mb-96"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className="flex flex-col justify-between">
                <TextField
                  type="text"
                  id="check_num"
                  value={data.check_num}
                  disabled={false}
                  onChange={handleChange}
                  error={main.error}
                  readonly={true}
                />
                <TextField
                  type="text"
                  id="employee"
                  disabled={false}
                  onChange={handleChange}
                  // label="Employee"
                  error={main.error}
                  readonly={false}
                  placeholder="Nombre del Empleado"
                />
                {/* <TextField
                type="number"
                id="ssn"
                disabled={false}
                onChange={handleChange}
                label="SSN (Last 4 digits)"
                error={main.error}
                readonly={false}
                max={9999}
              /> */}
                {/* <TextField
                  type="text"
                  id="net"
                  value={data.net}
                  disabled={false}
                  onChange={handleChange}
                  label="Net"
                  error={main.error}
                  readonly={false}
                  
                  
                /> */}
                <div className="flex flex-col w-full mb-3">
                  <CurrencyInput
                    id="net"
                    name="net"
                    placeholder="Valor neto"
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      setData({ ...data, [name]: value })
                    }
                    className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                    prefix="$"
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegativeValue={false}
                    allowDecimals={true}
                    groupSeparator=","
                  />
                  {main.error.errors &&
                    main.error?.errors["net"] &&
                    main.error?.errors["net"][0] && (
                      <div className="w-ful text-red-500 text-sm">
                        {main.error?.errors["net"][0]}
                      </div>
                    )}
                </div>
              </div>

              <div className="flex flex-col justify-between">
                {/* <TextField
                type="text"
                id="check_id"
                disabled={false}
                onChange={handleChange}
                label="Check Id"
                error={main.error}
                readonly={false}
              /> */}
                {/* <TextField
                type="text"
                id="gross"
                disabled={false}
                onChange={handleChange}
                label="Gross"
                error={main.error}
                readonly={false}
              />
              <TextField
                type="text"
                id="taxes"
                disabled={false}
                onChange={handleChange}
                label="Taxes"
                error={main.error}
                readonly={false}
              /> */}
              </div>

              <div className="flex flex-col justify-between">
                {/* <TextField
                type="text"
                id="status"
                value={data.status}
                disabled={false}
                onChange={handleChange}
                label="Status"
                error={main.error}
                readonly={true}
              /> */}
                <TextField
                  type="text"
                  id="company_fee"
                  value={`${data.company_fee}%`}
                  disabled={false}
                  onChange={handleChange}
                  label="Comisión"
                  error={main.error}
                  readonly={true}
                />
                <TextField
                  type="text"
                  id="total_fee"
                  value={data.total_fee}
                  disabled={false}
                  onChange={handleChange}
                  placeholder="Total Comisión"
                  error={main.error}
                  readonly={true}
                />
                <TextField
                  type="text"
                  id="total_to_pay"
                  value={data.total_to_pay}
                  disabled={false}
                  onChange={handleChange}
                  placeholder="Total a pagar"
                  error={main.error}
                  readonly={true}
                  className="bg-green-100 border-green-300"
                />
              </div>

              <div className="flex flex-col justify-between pb-3">
                <div className="w-full mb-1">
                  <TextField
                    type="text"
                    id="picked_up_by"
                    disabled={false}
                    onChange={handleChange}
                    label=""
                    placeholder="Nombre de quien retira"
                    error={main.error}
                    readonly={false}
                  />
                </div>
                <div className="w-full">
                  <TextField
                    type="text"
                    id="phone"
                    disabled={false}
                    onChange={handleChange}
                    label=""
                    placeholder="Teléfono"
                    error={main.error}
                    readonly={false}
                  />
                </div>
                <div className="w-full">
                  <Selector
                    onChange={changeSelector}
                    open={() => setModalAddClient(true)}
                  />
                </div>
              </div>

              {/* <div className="flex justify-center">
                {data.net > 0 && data.company_color == 'green' && (
                  <Submit
                    text="Imprimir"
                    icon="fa-solid fa-print"
                    state={main.btnLoading}
                  />
                )}
              </div> */}
            </form>
            <div className="w-8/12 px-10 print:w-full print:px-0">
              {!withdrawalModal && <Report data={data} ticket=""/>}
            </div>
            <div className="absolute top-0 right-0 bg-red-200 print:hidden">
                {data.net > 0 && data.company_color == 'green' && (
                  <SubmitAction
                    text="Imprimir"
                    icon="fa-solid fa-print"
                    state={main.btnLoading}
                    action={alternativePrint}
                  />
                )}
              </div>
          </div>
        )}
        {/* <div id="prueba">hola</div> */}
      </div>
      <CreateFromSelector
        modal={modalAddClient}
        close={() => setModalAddClient(false)}
      />
    </>
  );
}
