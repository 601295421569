import { useState } from "react";
import { SelectField, Submit, TextField } from "../../components/forms";
import { useDispatch, useSelector } from "react-redux";
import { filterValue } from "../../../state/actions/_transactions";
import { filterDetaisNull } from '../../../state/reducers/r_transaction';
import FilterDetails from "./filter-details";
import Moment from "react-moment";

export default function Filter() {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const transaction = useSelector((state) => state.tran.filter);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState();

  const options = [
    {
      id: "check_num",
      name: "Num. Cheque",
    },
    {
      id: "employee",
      name: "Empleado",
    },
    {
      id: "net",
      name: "Monto cheque",
    },
    {
      id: "whitelist_id",
      name: "Empresa",
    },
  ];

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(filterValue(data));
  };

  const handleData = (item) => {
    setItem(item);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false)
    dispatch(filterDetaisNull());
  }

  return (
    <div>
      <h1 className="w-10/12 font-bold mx-auto underline mb-5">
        Detalles de Transacciones
      </h1>
      <form
        action=""
        onSubmit={handleSubmit}
        className="w-10/12 mx-auto flex space-x-3 justify-center items-center"
      >
        <SelectField
          id="param"
          value=""
          disabled={false}
          onChange={handleChange}
          label="Filtrar por parámetro"
          error={main.error}
          options={options}
        />
        <TextField
          type="text"
          id="param_value"
          value=""
          disabled={false}
          onChange={handleChange}
          label="Parámetro"
          error=""
          readonly={false}
          placeholder="Especifique el valor aquí"
          maxlength={255}
          max={255}
          className=""
        />
        <div className="pt-3">
          <Submit
            text="Buscar"
            icon="fa-solid fa-search"
            state={main.btnLoading}
          />
        </div>
      </form>
      <div className="w-10/12 mx-auto mt-10">
        {transaction && transaction.length > 0 ? (
          <table className="table mb-10">
            <thead>
              <tr>
                <th>Date</th>
                <th>Cheque</th>
                <th>Nombre</th>
                <th>Teléfono</th>
                <th>Contacto</th>
                <th>Empresa</th>
                <th>Monto</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {transaction.map((item, key) => (
                <tr key={key} className="hover:bg-gray-200">
                  <td>
                    <Moment format="MM/DD/YYYY h:mm:ss a">
                      {item.updated_at}
                    </Moment>
                  </td>
                  <td>{item.check_num}</td>
                  <td>{item.employee}</td>
                  <td>{item.phone}</td>
                  <td>{item.picked_up_by}</td>
                  <td>{item.client?.name || item?.name}</td>
                  <td>{item.net}</td>
                  <td>{item.status}</td>
                  <td>
                    <i className="fa fa-eye opacity-40 opa hover:opacity-100 cursor-pointer" onClick={ () => handleData(item) }></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="bg-red border border-red-400 bg-red-200 p-3 text-red-500">
            No results
          </div>
        )}
      </div>
      {modal && (
        <FilterDetails details={item} modal={modal} close={() => closeModal()} />
      )}
    </div>
  );
}
