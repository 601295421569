import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { verifyLogin } from "../../state/actions/_auth";
import { auth } from "../../state/reducers/r_auth";
import Loading from "../components/Loading";
import Bg from '../../assets/img/bg.webp'

export default function Container() {
  const dispatch = useDispatch();
  const r_auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifyLogin());
    return () => {
      dispatch(auth(undefined));
    };
  }, [dispatch]);

  return r_auth.auth !== undefined ? (
    <div className="w-full" style={{ backgroundImage: `url(${Bg})`, backgroundRepeat: 'repeat' }}>
      <div className="w-10/12 mx-auto bg-white">
        <Header />
        <div className="w-full min-h-[700px]">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  ) : (
    <Loading />
  );
}
