/* eslint-disable array-callback-return */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Create from "./create";
import Update from "./update";
import Delete from "./delete";
import ToCashier from "../transactions/to-cashier";
import { Link } from "react-router-dom";
import { Info } from "../components/alerts";
import { modal } from "../../state/reducers/r_main";
import { useEffect, useState } from "react";

export default function List() {
  let { method, key } = useParams();
  const dispatch = useDispatch();
  const cashier = useSelector((state) => state.cashier);
  const config = useSelector((state) => state.config);
  const [total, setTotal] = useState(0);
  const [totalCashier, setTotalCashier] = useState(0);
  const [mainCashier, setMainCashier] = useState(0);

  const activateModal = () => {
    dispatch(modal(true));
  };

  useEffect(() => {
    let total = 0;
    if (cashier.cashier && cashier.cashier.length > 0) {
      cashier.cashier.map((item) => {
        total = parseFloat(total) + parseFloat(item.amount);
        setTotalCashier(total);
      });
    }
  }, [cashier.cashier]);

  useEffect(() => {
    config.config.map((item) => {
      if (item.code === "main_cashier")
        setMainCashier(item.value);
    });
  }, [config.config]);

  useEffect(() => {
    let total = parseFloat(totalCashier) + parseFloat(mainCashier);
    setTotal(total.toFixed(2));
  }, [totalCashier, mainCashier]);

  return (
    <>
      <div className="mt-10">
        <div className="w-11/12 mx-auto flex justify-between items-center">
          <h1 className="underline font-bold capitalize text-xl">
            Cashier & Cash Register
          </h1>
          <div className="flex justify-end items-center space-x-2">
            <Link
              to="/cashier/create"
              className="submenu"
              onClick={activateModal}
            >
              <i className="fa-solid fa-plus" /> Agregar Caja
            </Link>
            <Link
              to="/cashier/transaction"
              className="submenu"
              onClick={activateModal}
            >
              <i className="fa-solid fa-plus" /> Agregar fondos a caja
            </Link>
          </div>
        </div>
        {cashier.cashier ? (
          <div className="w-11/12 mx-auto">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Updated at</th>
                  <th>...</th>
                </tr>
              </thead>
              <tbody>
                {cashier.cashier.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.name}</td>
                    <td>
                      {Intl.NumberFormat("us-US").format(
                        parseFloat(item.amount).toFixed(2)
                      )}
                    </td>
                    <td>
                      <Moment fromNow>{item.updated_at}</Moment>
                    </td>
                    <td className="options">
                      <Link
                        to={`/cashier/update/${ind}`}
                        onClick={activateModal}
                      >
                        <i className="fa-solid fa-edit edit" />
                      </Link>
                      <Link
                        to={`/cashier/delete/${ind}`}
                        onClick={activateModal}
                      >
                        <i className="fa-solid fa-trash delete" />
                      </Link>
                    </td>
                  </tr>
                ))}
                {config.config &&
                  config.config.map(
                    (item, ind) =>
                      item.code === "main_cashier" && (
                        <tr key={ind}>
                          <td>Safe</td>
                          <td>
                            {Intl.NumberFormat("us-US").format(
                              parseFloat(item.value).toFixed(2)
                            )}
                          </td>
                          <td>
                            <Moment fromNow>{item.updated_at}</Moment>
                          </td>
                          <td></td>
                        </tr>
                      )
                  )}
                <tr className="bg-green-100">
                  <td className="font-bold">Total</td>
                  <td>
                    {Intl.NumberFormat("us-US").format(
                      parseFloat(total).toFixed(2)
                    )}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Info message="There is no records." />
        )}
      </div>
      {method === "create" && <Create />}
      {method === "update" && <Update ind={key} />}
      {method === "delete" && <Delete ind={key} />}
      {method === "transaction" && <ToCashier />}
    </>
  );
}
