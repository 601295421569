import api from '../../utils/api';
import { success, error, btnLoading } from '../reducers/r_main'

export const importFile = (data) => async (dispatch) => {
	dispatch(btnLoading(true))
	try {
		const response = await api.post('import', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		if (response.status === 200) {
			dispatch(success({
				status: true,
				message: `Archivo cargado correctamente. Se crearon ${response.data.total} registros. Por favor espere un momento mientras se procesa la información.`
			}))
		}
	} catch (err) {
		if (err.code === "ECONNABORTED") {
			dispatch(error({
				status: true,
				message: err.message,
				errors: []
			}))
		} else {
			dispatch(error({
				status: true,
				message: err.response.data.message,
				errors: err.response.data.errors
			}))
		}
		
	} finally {
		dispatch(btnLoading())
	}
}