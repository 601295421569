// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// import { verifyLogin } from '../../state/actions/_auth';
// import { auth } from '../../state/reducers/r_auth'

export default function AuthRequired(props) {
	// const dispatch = useDispatch();
	const r_auth = useSelector( state => state.auth );

	return (
		r_auth.auth ? props.children : <Navigate to="/login" />
	);
}