import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from "./app/components/container";
import Home from "./app/pages/home";
import Login from "./app/auth/login";
import routes from "./utils/routes";
import AuthRequired from "./app/components/auth-required";
import Docs from "./app/docs/index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Container />}>
          <Route path="" element={<Home />} />
          <Route path="" elemen={Home} />
          {routes.map((item, key) => (
            <Route
              key={key}
              path={item.path}
              element={<AuthRequired>{item.element}</AuthRequired>}
            />
          ))}
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/docs" element={<Docs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
