import CurrencyInput from "react-currency-input-field";

export const Currency = ({ value, onChange, id, error, label, disabled }) => {
  return (
    <div className="flex flex-col w-full items-center justify-center">
      {label && (
        <label htmlFor={id} className="font-bold text-gray-500">
          {label}
        </label>
      )}
      <CurrencyInput
        id={id}
        name={id}
        defaultValue={value}
        decimalsLimit={2}
        onValueChange={onChange}
        disabled={disabled || false}
        className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
        prefix="$"
        decimalScale={2}
        decimalSeparator="."
        allowNegativeValue={false}
        allowDecimals={true}
        groupSeparator=","
      />
      {error.errors && error?.errors[id] && error?.errors[id][0] && (
        <div className="w-ful text-red-500 text-sm">{error?.errors[id][0]}</div>
      )}
    </div>
  );
};

export const Submit = ({ text, icon, state, type = "submit" }) => {
  return state ? (
    <button
      className="bg-blue-300 text-white font-bold py-2 px-5 border-b-4 border-blue-400 cursor-not-allowed  flex justify-center items-center"
      disabled
    >
      <i className="fa-solid fa-spinner fa-spin mr-2" />
      Loading...
    </button>
  ) : (
    <button
      className="bg-blue-500 
        text-white font-bold py-2 px-5 border-b-4 border-blue-700 
        hover:opacity-90 hover:scale-105 
        active:scale-95 flex justify-center items-center"
      type={type}
    >
      <i className={`${icon} mr-1`} />
      {text}
    </button>
  );
};

export const SubmitAction = ({ text, icon, state, type = "button", action }) => {
  return state ? (
    <button
      className="bg-blue-300 text-white font-bold py-2 px-5 border-b-4 border-blue-400 cursor-not-allowed  flex justify-center items-center"
      disabled
    >
      <i className="fa-solid fa-spinner fa-spin mr-2" />
      Loading...
    </button>
  ) : (
    <button
      className="bg-blue-500 
        text-white font-bold py-2 px-5 border-b-4 border-blue-700 
        hover:opacity-90 hover:scale-105 
        active:scale-95 flex justify-center items-center"
      type={type}
      onClick={action}
    >
      <i className={`${icon} mr-1`} />
      {text}
    </button>
  );
};

export const SubmitGreen = ({ text, icon, state, type = "submit" }) => {
  return state ? (
    <button
      className="bg-blue-300 text-white font-bold py-2 px-5 border-b-4 border-blue-400 cursor-not-allowed  flex justify-center items-center"
      disabled
    >
      <i className="fa-solid fa-spinner fa-spin mr-2" />
      Loading...
    </button>
  ) : (
    <button
      className="bg-green-500 
        text-white font-bold py-2 px-5 border-b-4 border-green-700 
        hover:opacity-90 hover:scale-105 
        active:scale-95 flex justify-center items-center"
      type={type}
    >
      <i className={`${icon} mr-1`} />
      {text}
    </button>
  );
};

export const TextField = ({
  type,
  id,
  value,
  disabled,
  onChange,
  label,
  error,
  readonly,
  placeholder,
  maxlength,
  max,
  className,
}) => {
  return (
    <div className="flex flex-col w-full mb-3">
      {label && (
        <label htmlFor={id} className="font-bold text-gray-500">
          {label}:
        </label>
      )}
      <input
        className={`w-full border border-gray-400 p-2 focus:outline-none focus:ring-2 ${
          className || ""
        }`}
        type={type}
        name={id}
        id={id}
        defaultValue={value}
        disabled={disabled || false}
        readOnly={readonly || false}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxlength}
        max={max}
      />
      {error.status && (
        <>
          {error.errors && error?.errors[id] && error?.errors[id][0] && (
            <div className="w-ful text-red-500 text-sm">
              {error?.errors[id][0]}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const SelectField = ({
  id,
  value,
  disabled,
  onChange,
  label,
  error,
  options,
}) => {
  return (
    <div className="flex flex-col w-full mb-3">
      {label && (
        <label htmlFor={id} className="font-bold text-gray-500">
          {label}:
        </label>
      )}
      <select
        className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
        name={id}
        id={id}
        defaultValue={value}
        disabled={disabled || false}
        onChange={onChange}
      >
        <option value="">Select an option</option>
        {options.map((item, key) => (
          <option key={key} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {error.status && (
        <>
          {error.errors && error?.errors[id] && error?.errors[id][0] && (
            <div className="w-ful text-red-500 text-sm">
              {error.errors[id][0]}
            </div>
          )}
        </>
      )}
    </div>
  );
};
