/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal";
import { Submit } from "../../components/forms";
import { cashierToCashier } from "../../../state/actions/_transactions";
// import { getRecords } from "../../../state/actions/_config";
import CurrencyInput from "react-currency-input-field";
import Loading from "../../components/Loading";
import { getRecords as getCashiers } from "../../../state/actions/_cashier";
import { SelectField } from "../../components/forms";

export default function Transfer({ close, status, cashier }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    amount: cashier.amount,
    from_cashier: cashier.id
  });
  const [dataCashier, setDataCashier] = useState({});
  const [currentAmount, setCurrentAmount] = useState(cashier.amount);
  const main = useSelector((state) => state.main);
  const rcashier = useSelector((state) => state.cashier);
  const tran = useSelector((state) => state.tran);

  useEffect(() => {
    if (!rcashier.loaded) {
      dispatch(getCashiers());
    }
  }, [dispatch, rcashier.loaded]);

  useEffect(() => {
    let result;
    if (rcashier.loaded) {
      result = rcashier.cashier.filter((item) => item.id !== cashier.id);
      setDataCashier(result);
    }
  }, [rcashier.loaded]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(data);
    dispatch(cashierToCashier(data, close));
  };

  // console.log(dataCashier);

  return (
    <>
      <Modal
        size="w-1/2"
        title={`New transaction from main to ${cashier.name}`}
        state={status}
        close={close}
      >
        {currentAmount ? (
          <form action="" className="w-2/3 mx-auto" onSubmit={handleSubmit}>
            <div className="flex flex-col w-full mb-3">
              <label htmlFor="net" className="font-bold text-gray-500">
                Current Amount:
              </label>
              <CurrencyInput
                id=""
                name=""
                defaultValue={currentAmount}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  setData({ ...data, [name]: value })
                }
                disabled={true}
                className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                prefix="$"
                decimalScale={2}
                decimalSeparator="."
                allowNegativeValue={false}
                allowDecimals={true}
                groupSeparator=","
              />
            </div>

            <div className="flex flex-col w-full mb-3">
              <label htmlFor="net" className="font-bold text-gray-500">
                Amount to transfer:
              </label>
              <CurrencyInput
                id="amount"
                name="amount"
                defaultValue={data.amount}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  setData({ ...data, [name]: value })
                }
                disabled={false}
                className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                prefix="$"
                decimalScale={2}
                decimalSeparator="."
                allowNegativeValue={false}
                allowDecimals={true}
                groupSeparator=","
              />
              {tran.error.errors &&
                tran.error?.errors["amount"] &&
                tran.error?.errors["amount"][0] && (
                  <div className="w-ful text-red-500 text-sm">
                    {tran.error?.errors["amount"][0]}
                  </div>
                )}
            </div>

            {dataCashier.length > 0 && (
              <SelectField
                id="cashier_id"
                value=""
                disabled={false}
                onChange={(e) =>
                  setData({ ...data, cashier_id: e.target.value })
                }
                label="Cash Register"
                options={dataCashier}
                error={tran.error}
              />
            )}
            <div className="flex justify-center mt-7">
              <Submit
                text="Add amount"
                icon="fa-solid fa-check"
                state={main.btnLoading}
              />
            </div>
          </form>
        ) : (
          <Loading />
        )}
      </Modal>
    </>
  );
}
