import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getRecords } from "../../state/actions/_whitelist";
import Loading from "../components/Loading";
import List from "./list";


export default function Whitelist() {
  const dispatch = useDispatch();
  const whitelist = useSelector((state) => state.whitelist);
  const main = useSelector((state) => state.main);

  useEffect(() => {
    if (!whitelist.loaded) {
      dispatch(getRecords());
    }
  }, [dispatch, whitelist.loaded]);

  return main.loading ? (
    <Loading />
  ) : (
    <>
      <Routes>
        {["", ":method", ":method/:key"].map(
          (path, key) => (
            <Route key={key} path={path} element={<List />} />
          )
        )}
      </Routes>
    </>
  );
}
