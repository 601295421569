import { useState, useEffect } from "react";
import Moment from "react-moment";

export default function ToPrint({ data, total, rows }) {
  const [amounts, setAmounts] = useState(undefined);

  // console.log(data);
  useEffect(() => {
    // console.log(data);
    let elements = [];
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      let result = item.net ? item.net.split(".") : null;
      // console.log(result);
      if (result && result.length > 0) {
        elements.push({
          check: item.check_num,
          dollars: result[0],
          cents: result[1],
        });
      }
    });
    // console.log(elements);
    setAmounts(elements);
  }, [data]);

  const Loop = ({ amount }) => {
    let content = [];
    for (let x = 0; x <= rows; x++) {
      content.push({ num: x });
    }
    return content.map((item, key) => (
      <tr key={key}>
        <td>{item.num + amount + 1}</td>
        <td></td>
        <td></td>
      </tr>
    ));
  };

  // console.log(amounts)

  return (
    amounts && (
      <div className="w-full hidden print:block">
        <img
          className="w-1/2 mx-auto"
          src={require("../../assets/img/SmartCash_Logo_HD.png")}
          alt=""
        />
        <div className="flex flex-col text-[10px] justify-center items-center mb-2">
          <span className="font-bold">Smart Cash LLC</span>
          <span className="font-bold">
            <Moment format="MMMM Do YYYY, h:mm:ss a" />
          </span>
          {/* <span>904 Main Street</span>
          <span>Pleasent Grove, UT 84062</span>
          <span>(901)935-6593</span> */}
        </div>
        {/* <div className="flex flex-col items-center justify-center text-[10px] border-t-4 border-gray-400 pt-2 mb-2">
          <span className="text-center">Deposits may not be available for inmediate withdrawal</span>
          <span>Endorse & List checks separately or attached</span>
        </div> */}
        <div className="flex">
          <div className="w-1/12  relative">
            <div className="rotate-90 absolute top-48 right-[-100px]">
              <span className="font-bold mr-10">TOTAL:</span>
              <span className="mr-10">{data.length}</span>
              <span className="mr-4">$</span>
              <span>{total}</span>
            </div>
          </div>
          <table className="report_2 w-11/12">
            <thead>
              <tr>
                <th className="w-6/12"></th>
                <th className="w-4/12">Dollars</th>
                <th className="w-2/12">Cents</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>Currency</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Coin</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total Cash</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Checks</td>
                <td></td>
                <td></td>
              </tr> */}
              {amounts.map((item, key) => (
                <tr key={key}>
                  <td className="text-left">
                    <span className="mr-3">{key + 1}</span> {item.check}
                  </td>
                  <td className="text-right">{item.dollars}</td>
                  <td className="text-right">.{item.cents || "00"}</td>
                </tr>
              ))}
              {rows > 0 && <Loop amount={amounts.length} />}
              <tr>
                <td className="font-bold text-right">Total:</td>
                <td className="font-bold text-right" colSpan={2}>
                  ${total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}
