import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modal";
import { modal, error } from "../../state/reducers/r_main";
import { Submit, TextField, SelectField } from "../components/forms";
import { Error } from "../components/alerts";
import { storeRecord } from "../../state/actions/_users";

export default function Create() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const main = useSelector((state) => state.main);
  const role = useSelector(state => state.role);
  const [data, setData] = useState({
    role_id: 1
  });

  const close = () => {
    dispatch(modal());
    dispatch(error());
    navigate("/users");
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(storeRecord(data));
  };

  return (
    <Modal size="w-1/2" title="Add User" state={main.modal} close={close}>
      <div className="w-3/4 mx-auto mb-2">
        <Error
          state={main.error.status}
          message={main.error.message}
          close={() => dispatch(error())}
        />
      </div>
      <form
        action=""
        className="w-3/4 mx-auto p-5 bg-gray-100 shadow"
        onSubmit={handleSubmit}
      >
        <TextField
          type="text"
          id="name"
          value=""
          disabled={false}
          onChange={handleChange}
          label="Name"
          error={main.error}
        />
        <TextField
          type="text"
          id="email"
          value=""
          disabled={false}
          onChange={handleChange}
          label="Email"
          error={main.error}
        />
        <SelectField
          id="role_id"
          value={data.role_id}
          disabled={false}
          onChange={handleChange}
          label="Role"
          options={role.roles}
          error={main.error}
        />
        <div className="flex justify-center mt-5">
          <Submit
            text="Create"
            icon="fa-solid fa-plus"
            state={main.btnLoading}
          />
        </div>
      </form>
    </Modal>
  );
}
