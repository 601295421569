import Modal from "../../components/modal";
import Moment from "react-moment";
import { filterDetails } from "../../../state/actions/_transactions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function FilterDetails({ details, modal, close }) {
  const dispatch = useDispatch();
  const filter_details = useSelector((state) => state.tran.filter_details);

  useEffect(() => {
    dispatch(filterDetails(details.id));
  }, [details]);

  console.log(filter_details);

  return (
    <Modal size="w-1/2" title="Details" state={modal} close={close}>
      {filter_details  && (
        <table className="details">
          <tbody>
            <tr>
              <th>Check Number:</th>
              <td>{filter_details.check?.check_num}</td>
            </tr>
            <tr>
              <th>Employee:</th>
              <td>{filter_details.check?.employee}</td>
            </tr>
            <tr>
              <th>Net:</th>
              <td>${filter_details.check?.net}</td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>{filter_details.check?.status}</td>
            </tr>
            <tr>
              <th>Company Fee:</th>
              <td>{filter_details.check?.company_fee}</td>
            </tr>
            <tr>
              <th>Total Fee:</th>
              <td>${filter_details.check?.total_fee}</td>
            </tr>
            <tr>
              <th>Total To Pay:</th>
              <td>${filter_details.check?.total_to_pay}</td>
            </tr>
            {/* <tr>
					<th>Receipt URL:</th>
					<td><a className='text-blue-400' target="_blank"  href={`${process.env.REACT_APP_URL}/${details.receipt_url}`} rel="noreferrer">{details.receipt_url}</a></td>
				</tr> */}
            <tr>
              <th>Picked Up By:</th>
              <td>{filter_details.check?.picked_up_by}</td>
            </tr>
            <tr>
              <th>Phone:</th>
              <td>{filter_details.check?.phone}</td>
            </tr>
            <tr>
              <th>Company Name:</th>
              <td>
                <span
                  className="w-5 h-5 rounded-full mr-2 inline-block align-middle"
                  style={{ backgroundColor: filter_details.check?.client?.flag }}
                ></span>
                {filter_details.check?.client?.name}
              </td>
            </tr>
            <tr>
              <th>Cashier</th>
              <td>{filter_details.cashier?.name} - {filter_details.user?.name} </td>
            </tr>
            <tr>
              <th>Fecha y Hora</th>
              <td>
                <Moment format="MM/DD/YYYY hh:mm a">
                  {filter_details.updated_at}
                </Moment>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </Modal>
  );
}
