import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Modal from "../components/modal";
import { TextField, Submit, SelectField } from "../components/forms";
import { mainToCashier } from "../../state/actions/_transactions";
import { getRecords } from "../../state/actions/_config";
import { modal, error } from "../../state/reducers/r_main";
import CurrencyInput from "react-currency-input-field";

export default function ToMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [currentAmount, setCurrentAmount] = useState();
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);
  const cashier = useSelector((state) => state.cashier);

  useEffect(() => {
    if (config.loaded) {
      let result = config.config.filter((item) => item.code == "main_cashier");
      if (result.length > 0) {
        setCurrentAmount(result[0].value);
      }
    } else {
      dispatch(getRecords());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.loaded]);

  const closeModal = () => {
    dispatch(modal());
    dispatch(error());
    navigate("/cashier");
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(mainToCashier(data));
  };

  return (
    <>
      {main.success.status && <Navigate to="/cashier" />}
      <Modal
        size="w-1/2"
        title="New transaction from main to cashier"
        state={main.modal}
        close={closeModal}
      >
        <form action="" className="w-2/3 mx-auto" onSubmit={handleSubmit}>
          {/* <TextField
            size="w-1/2"
            type="text"
            value={currentAmount}
            disabled={true}
            readonly={true}
            onChange={handleChange}
            label="Available Amount (Main Cashier)"
            error={main.error}
          /> */}
          {currentAmount && (
            <div className="flex flex-col w-full mb-3">
              <label htmlFor="net" className="font-bold text-gray-500">
                Current Amount:
              </label>
              <CurrencyInput
                id=""
                name=""
                defaultValue={currentAmount}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  setData({ ...data, [name]: value })
                }
                disabled={true}
                className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                prefix="$"
                decimalScale={2}
                decimalSeparator="."
                allowNegativeValue={false}
                allowDecimals={true}
                groupSeparator=","
              />
            </div>
          )}
          <SelectField
            id="cashier_id"
            value=""
            disabled={false}
            onChange={handleChange}
            label="Cash Register"
            options={cashier.cashier}
            error={main.error}
          />
          {/* <TextField
            size="w-1/2"
            id="amount"
            type="text"
            value=""
            disabled={false}
            onChange={handleChange}
            label="Amount to Add"
            error={main.error}
          /> */}
          <div className="flex flex-col w-full mb-3">
            <label htmlFor="amount" className="font-bold text-gray-500">
              Amount to add:
            </label>
            <CurrencyInput
              id="amount"
              name="amount"
              decimalsLimit={2}
              onValueChange={(value, name) =>
                setData({ ...data, [name]: value })
              }
              className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
              prefix="$"
              decimalScale={2}
              decimalSeparator="."
              allowNegativeValue={false}
              allowDecimals={true}
              groupSeparator=","
            />
            {main.error.errors && main.error?.errors['amount'] && main.error?.errors['amount'][0] && (
              <div className="w-ful text-red-500 text-sm">
                {main.error?.errors['amount'][0]}
              </div>
            )}
          </div>
          <div className="flex justify-center mt-7">
            <Submit
              text="Add amount"
              icon="fa-solid fa-check"
              state={main.btnLoading}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
