import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Submit } from "../components/forms";
import { Success, Error } from "../components/alerts";
import { importFile } from "../../state/actions/_import";
import { error, success } from "../../state/reducers/r_main";
import { useEffect } from "react";

export default function Import() {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const [data, setData] = useState();
  const uploadForm = useRef();

  const handleChange = (event) => {
    setData(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("excel_file", data);
    dispatch(importFile(formData));
  };

  useEffect(() => {
    if (main.success.status) {
      uploadForm.current.reset();
    }
  }, [main.success.status]);

  return (
    <form
      action=""
      className="w-1/2 mx-auto mt-10 bg-gray-100 p-5 shadow"
      onSubmit={handleSubmit}
      ref={uploadForm}
    >
      {main.error.status && (
        <div className="mb-5">
          <Error
            state={main.error.status}
            message={main.error.message}
            close={() => dispatch(error())}
          />
        </div>
      )}
      {main.success.status && (
        <div className="mb-5">
          <Success
            state={main.success.status}
            message={main.success.message}
            close={() => dispatch(success())}
          />
        </div>
      )}
      <TextField
        type="file"
        id="excel_file"
        value=""
        disabled={false}
        onChange={handleChange}
        label="Import Excel File"
        error={main.error}
        readonly={false}
      />
      <div className="flex justify-center mt-5">
        <Submit
          text="Send File"
          icon="fa-solid fa-upload"
          state={main.btnLoading}
        />
      </div>
    </form>
  );
}
