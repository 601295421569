import api from '../../utils/api';
import { loading, btnLoading, error, modal } from '../reducers/r_main';
import { whitelist, loaded } from '../reducers/r_whitelist';

export const getRecords = () => async (dispatch) => {
	dispatch(loading(true));
	dispatch(error());
	try {
		const response = await api.get('whitelist');
		dispatch(whitelist(response.data));
		dispatch(error());
	} catch (err) {
		console.error(err.response.data);
	} finally {
		dispatch(loading());
	}
}

export const storeRecord = (data, success = null) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.post(`whitelist`, data);
		dispatch(loaded());
		dispatch(modal());
		if (success) {
			success()
		}
		// dispatch(success({
		// 	status: true,
		// 	message: 'User stored successfully.'
		// }))
	} catch (err) {	
		dispatch(error({
			status: true,
			message: err.response.data.message,
			errors: err.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}

export const updateRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.put(`whitelist/${data.id}`, data);
		dispatch(loaded());
		dispatch(modal());
		// dispatch(success({
		// 	status: true,
		// 	message: 'User updated successfully.'
		// }))
	} catch (err) {	
		dispatch(error({
			status: true,
			message: err.response.data.message,
			errors: err.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}

export const deleteRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.delete(`whitelist/${data.id}`, data);
		dispatch(loaded());
		dispatch(modal())
		// dispatch(success({
		// 	status: true,
		// 	message: 'User deleted successfully.'
		// }))
	} catch (error) {	
		dispatch(error({
			status: true,
			message: error.response.data.message,
			errors: error.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}