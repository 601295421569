import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API,
	timeout: 480000,
	headers: {
		'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
		"Content-Type": "application/json",
		"Accept": "application/json"
	}
})

export default axiosInstance;