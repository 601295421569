import Users from '../app/users/users';
import Roles from '../app/roles/roles';
import Config from '../app/config/config'
import Cashier from '../app/cashier/cashier'
import Import from '../app/import/import'
import Checks from '../app/checks/checks';
import Transactions from '../app/report/transactions'
import Whitelist from '../app/whitelist/whitelist';
import History from '../app/report/history/history'
import Balance from '../app/report/reports/balance'
import Filter from '../app/report/reports/filter'

const routes = [
	{
		path: 'users/*',
		element: <Users />,
		private: true
	},
	{
		path: 'roles/*',
		element: <Roles />,
		private: true
	},
	{
		path: 'whitelist/*',
		element: <Whitelist />,
		private: true
	},
	{
		path: 'config/*',
		element: <Config />,
		private: true
	},
	{
		path: 'cashier/*',
		element: <Cashier />,
		private: true
	},
	{
		path: 'import',
		element: <Import />,
		private: true
	},
	{
		path: 'dispatch/*',
		element: <Checks />,
		private: true
	},
	{
		path: 'transactions',
		element: <Transactions />,
		private: true
	},
	{
		path: 'history/*',
		element: <History />,
		private: true
	},
	{
		path: 'balance/*',
		element: <Balance />,
		private: true
	},
	{
		path: 'filter/*',
		element: <Filter />,
		private: true
	}
]

export default routes;