/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from 'react-router-dom'
import Moment from "react-moment";
import Loading from "../../components/Loading";
import { Info } from "../../components/alerts";
// import { SelectField, Submit } from "../../components/forms";
import { getClosed } from "../../../state/actions/_transactions";
import Details from "./details";
import { Link } from 'react-router-dom'

export default function History() {
  const dispatch = useDispatch();
  const tran = useSelector((state) => state.tran);
  const main = useSelector((state) => state.main);
  // const [total, setTotal] = useState(undefined);
  // const [cash, setCash] = useState();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(getClosed());
  }, []);

  const handleData = (details) => {
    setData(details);
    setModal(true);
  };

  // useEffect( () => {

  // },[data])

  return main.loading ? (
    <Loading />
  ) : (
    <div className="mt-10">
      <div className="flex justify-between items-center w-11/12 mx-auto print:hidden">
        <div className="w-2/5 flex space-x-1">
          <Link
            to="/transactions"
            className="bg-gray-600 text-white p-2 rounded text-sm hover:opacity-70"
          >
            <i className="fa-solid fa-arrow-left" /> Volver a Transacciones
          </Link>
          </div>
      </div>
      {tran.closed && tran.closed.length > 0 ? (
        <div className="mt-5 print:hidden">
          <table className="table w-11/12 mx-auto my-10">
            <thead>
              <tr>
                <th>Check No</th>
                <th>Employee</th>
                <th>Picked up by</th>
                <th>Amount</th>
                <th>Updated</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {tran.closed.map((item, key) => (
                <tr key={key}>
                  <td>{item.check_num}</td>
                  <td>{item.employee}</td>
                  <td>{item.picked_up_by}</td>
                  <td>{item.net}</td>
                  <td>
                    <Moment format="MM/DD/YYYY hh:mm a">
                      {item.transaction?.updated_at}
                    </Moment>
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-eye text-blue-300 hover:text-blue-500 cursor-pointer"
                      onClick={() => handleData(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {modal && (
            <Details
              details={data}
              modal={modal}
              close={() => setModal(false)}
            />
          )}
        </div>
      ) : (
        <div className="print:hidden">
          <Info state={true} message="There is not transactions" />
        </div>
      )}
    </div>
  );
}
