import uno from "../../assets/img/docs/1.png";
import dos from "../../assets/img/docs/2.png";
import tres from "../../assets/img/docs/3.png";
import cuatro from "../../assets/img/docs/4.png";
import cinco from "../../assets/img/docs/5.png";
import seis from "../../assets/img/docs/6.png";
import siete from "../../assets/img/docs/7.png";
import ocho from "../../assets/img/docs/8.png";
import nueve from "../../assets/img/docs/9.png";
import diez from "../../assets/img/docs/10.png";
import once from "../../assets/img/docs/11.png";
import doce from "../../assets/img/docs/12.png";

export default function Docs() {
  return (
    <>
      <Section image={uno}></Section>
      <Section image={dos}></Section>
      <Section image={tres}></Section>
      <Section image={cuatro}></Section>
      <Section image={cinco}></Section>
      <Section image={seis}></Section>
      <Section image={siete}></Section>
      <Section image={ocho}></Section>
      <Section image={nueve}></Section>
      <Section image={diez}></Section>
			<Section image={once}></Section>
			<Section image={doce}></Section>
    </>
  );
}

export const Section = ({ image, children }) => {
  return (
    <div className="flex w-3/5 mx-auto py-10 border-b border-gray-200">
      <img src={image} alt="" className="border shadow" />
      <div>{children}</div>
    </div>
  );
};
