import Modal from '../../components/modal';
import Moment from "react-moment";

export default function Details( { details, modal, close } ) {

	// console.log(details)
	return <Modal  size="w-1/2" title="Details" state={modal} close={close}>
		{details && <table className='details'>
			<tbody>
				<tr>
					<th>Check Number:</th>
					<td>{details.check_num}</td>
				</tr>
				<tr>
					<th>Employee:</th>
					<td>{details.employee}</td>
				</tr>
				<tr>
					<th>Net:</th>
					<td>${details.net}</td>
				</tr>
				<tr>
					<th>Status:</th>
					<td>{details.status}</td>
				</tr>
				<tr>
					<th>Company Fee:</th>
					<td>{details.company_fee}%</td>
				</tr>
				<tr>
					<th>Total Fee:</th>
					<td>${details.total_fee}</td>
				</tr>
				<tr>
					<th>Total To Pay:</th>
					<td>${details.total_to_pay}</td>
				</tr>
				{/* <tr>
					<th>Receipt URL:</th>
					<td><a className='text-blue-400' target="_blank"  href={`${process.env.REACT_APP_URL}/${details.receipt_url}`} rel="noreferrer">{details.receipt_url}</a></td>
				</tr> */}
				<tr>
					<th>Picked Up By:</th>
					<td>{details.picked_up_by}</td>
				</tr>
				<tr>
					<th>Phone:</th>
					<td>{details.phone}</td>
				</tr>
				<tr>
					<th>Company Name:</th>
					<td><span className='w-5 h-5 rounded-full mr-2 inline-block align-middle' style={{backgroundColor: details.client?.flag}}></span>{details.client?.name}</td>
				</tr>
				<tr>
					<th>Cashier</th>
					<td>{details.transaction?.cashier?.name}</td>
				</tr>
				<tr>
					<th>Fecha y Hora</th>
					<td><Moment format="MM/DD/YYYY hh:mm a">{details.transaction?.updated_at}</Moment></td>
				</tr>
			</tbody>
		</table>}
	</Modal>
}