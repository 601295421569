import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    completed: [],
    printing: false,
    receipt_print: false,
    receipt_printing_done: false,
    error: {
      status: false,
      message: "",
      errors: [],
    },
    closed: [],
    completed_loaded: false,
    search: [],
    history: [],
    filter: [],
    filter_details: {}
  },
  reducers: {
    completed: (state, action) => {
      state.completed = action.payload ? action.payload : [];
      state.completed_loaded = true;
    },
    receiptPrint: (state, action) => {
      state.receipt_print = action.payload ? action.payload : false;
    },
    printingDone: (state, action) => {
      state.receipt_printing_done = action.payload ? action.payload : false;
    },
    withdrawal: (state, action) => {
      state.success = action.payload.success;
      state.fail = action.payload.fail;
    },
    errorTran: (state, action) => {
      state.error.status = action.payload?.status
        ? action.payload.status
        : false;
      state.error.message = action.payload?.message
        ? action.payload.message
        : "";
      state.error.errors = action.payload?.errors ? action.payload.errors : [];
    },
    closed: (state, action) => {
      state.closed = action.payload ? action.payload : [];
    },
    completedLoaded: (state, action) => {
      state.completed_loaded = action.payload ? action.payload : false;
    },
    searchRedu: (state, action) => {
      state.search = action.payload ? action.payload : [];
    },
    history: (state, action) => {
      state.history = action.payload ? action.payload : [];
    },
    filterParam: (state, action) => {
      state.filter = action.payload ? action.payload : [];
    },
    filterDetais: (state, action) => {
      state.filter_details = action.payload ? action.payload : {};
    },
    filterDetaisNull: (state, action) => {
      state.filter_details = {};
    },
  },
});

export const {
  completed,
  receiptPrint,
  printingDone,
  withdrawal,
  errorTran,
  closed,
  completedLoaded,
  searchRedu,
	history,
  filterParam,
  filterDetais,
  filterDetaisNull
} = transactionSlice.actions;
export default transactionSlice.reducer;
