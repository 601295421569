import Moment from "react-moment";

export default function Receipt( { data, hidden } ) {
  return (
    data && (
      <div className={`${hidden ? 'hidden' : ''} print:block w-full text-xs`}>
        <div className="w-full mx-auto">
          <img className="w-1/2 mx-auto" src={require('../../../assets/img/SmartCash_Logo_HD.png')} alt="" />
          <div className='w-full text-center mb-5 text-xs'>904 Main Street, Pleasent Grove, UT 84062</div>
        </div>
        <div className="text-center uppercase font-bold">Receipt Withdrawal</div>
        <table className="report">
          <tbody>
            <tr>
              <th>Date:</th>
              <td className="flex flex-col items-end">
                <Moment format="MM/DD/YYYY H:m:s" />
              </td>
            </tr>
            <tr>
              <th>Concept:</th>
              <td>{data.concept}</td>
            </tr>
            <tr>
              <th>Phone Number:</th>
              <td></td>
            </tr>
            <tr>
              <th>Company Name:</th>
              <td>PF</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{data.net}</td>
            </tr>
            <tr>
              <th>Total Amount:</th>
              <td>{data.amount}</td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex justify-end space-x-3 mt-10">
          <span className="w-1/2 text-right">Signature</span>
          <span className="w-1/2 border-b-2 border-gray-400"></span>
        </div>
      </div>
    )
  );
}
