import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getRecords } from "../../state/actions/_config";
import Loading from "../components/Loading";
import List from "./list";
import { success } from '../../state/reducers/r_main';


export default function Config() {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const main = useSelector((state) => state.main);

  useEffect(() => {
    dispatch(success());
    if (!config.loaded) {
      dispatch(getRecords());
    }
  }, [dispatch, config.loaded]);

  return main.loading ? (
    <Loading />
  ) : (
    <>
      <Routes>
        {["", ":method", ":method/:key"].map(
          (path, key) => (
            <Route key={key} path={path} element={<List />} />
          )
        )}
      </Routes>
    </>
  );
}
