import { createSlice } from "@reduxjs/toolkit";

const mainSlice = createSlice({
	name: 'main',
	initialState: {
		loading: false,
		btnLoading: false,
		modal: true,
		error: {
			status: false,
			message: "",
			errors: []
		},
		success: {
			status: false,
			message: ""
		}
	},
	reducers: {
		loading: (state, action) => {
			state.loading = action.payload ? action.payload : false;
		},
		btnLoading: (state, action) => {
			state.btnLoading = action.payload ? action.payload : false;
		},
		modal: (state, action) => {
			state.modal = action.payload ? action.payload : false;
		},
		error: (state, action) => {
			state.error.status = action.payload?.status ? action.payload.status : false;
			state.error.message = action.payload?.message ? action.payload.message : "";
			state.error.errors = action.payload?.errors ? action.payload.errors : [];
		},
		success: (state, action) => {
			state.success.status = action.payload?.status ? action.payload.status : false;
			state.success.message = action.payload?.message ? action.payload.message : "";
		},
	}
})

export const { loading, btnLoading, modal, error, success } = mainSlice.actions;
export default mainSlice.reducer;