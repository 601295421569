import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal";
import { Submit } from "../../components/forms";
import { mainToCashierFromChecks } from "../../../state/actions/_transactions";
import { getRecords } from "../../../state/actions/_config";
import CurrencyInput from "react-currency-input-field";
import Loading from "../../components/Loading";

export default function Deposit({ close, status, cashier }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
		cashier_id: cashier.id
	});
  const [currentAmount, setCurrentAmount] = useState();
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);

  useEffect(() => {
    if (config.loaded) {
      let result = config.config.filter((item) => item.code === "main_cashier");
      if (result.length > 0) {
        setCurrentAmount(result[0].value);
      }
    } else {
      dispatch(getRecords());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.loaded]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(mainToCashierFromChecks(data, close));
  };

  return (
    <>
      <Modal
        size="w-1/2"
        title={`New transaction from main to ${cashier.name}`}
        state={status}
        close={close}
      >
        {currentAmount ? (
          <form action="" className="w-2/3 mx-auto" onSubmit={handleSubmit}>
            <div className="flex flex-col w-full mb-3">
              <label htmlFor="net" className="font-bold text-gray-500">
                Current Amount:
              </label>
              <CurrencyInput
                id=""
                name=""
                defaultValue={currentAmount}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  setData({ ...data, [name]: value })
                }
                disabled={true}
                className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                prefix="$"
                decimalScale={2}
                decimalSeparator="."
                allowNegativeValue={false}
                allowDecimals={true}
                groupSeparator=","
              />
            </div>

            <div className="flex flex-col w-full mb-3">
              <label htmlFor="amount" className="font-bold text-gray-500">
                Amount to add:
              </label>
              <CurrencyInput
                id="amount"
                name="amount"
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  setData({ ...data, [name]: value })
                }
                className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                prefix="$"
                decimalScale={2}
                decimalSeparator="."
                allowNegativeValue={false}
                allowDecimals={true}
                groupSeparator=","
              />
              {main.error.errors &&
                main.error?.errors["amount"] &&
                main.error?.errors["amount"][0] && (
                  <div className="w-ful text-red-500 text-sm">
                    {main.error?.errors["amount"][0]}
                  </div>
                )}
            </div>
            <div className="flex justify-center mt-7">
              <Submit
                text="Add amount"
                icon="fa-solid fa-check"
                state={main.btnLoading}
              />
            </div>
          </form>
        ) : (
          <Loading />
        )}
      </Modal>
    </>
  );
}
