import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
	name: 'role',
	initialState: {
		config: [],
		loaded: false,
	},
	reducers: {
		config: (state, action) => {
			state.config = action.payload;
			state.loaded = true;
		},
		loaded: (state, action) => {
			state.loaded = action.payload ? action.payload : false
		}
	}
})

export const { config, loaded } = configSlice.actions;
export default configSlice.reducer;