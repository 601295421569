import { createSlice } from "@reduxjs/toolkit";

const checkSlice = createSlice({
  name: "check",
  initialState: {
    checks: [],
    loaded: false,
    cashier_details: {},
    cashier_detailes_loaded: false,
    receipt: {},
    receipt_loaded: false,
		receipt_print: false,
		receipt_printing_done: false,
    total_checks: 0
  },
  reducers: {
    checks: (state, action) => {
      state.checks = action.payload ? action.payload : [];
    },
    loaded: (state, action) => {
      state.loaded = action.payload ? action.payload : false;
    },
    filterCheck: (state, action) => {
      state.checks = state.checks.filter((item) => item.id === action.payload);
    },
    cashierDetails: (state, action) => {
      state.cashier_details = action.payload;
      state.cashier_details_loaded = true;
    },
    cashierDetailsLoaded: (state) => {
      state.cashier_details_loaded = false;
    },
    receipt: (state, action) => {
      state.receipt = action.payload ? action.payload : {};
      state.receipt_loaded = action.payload ? true : false;
    },
		receiptPrint: (state, action) => {
			state.receipt_print = (action.payload) ? action.payload : false;
		},
		printingDone: (state, action) => {
			state.receipt_printing_done = (action.payload) ? action.payload : false
		},
    totalChecks: (state,action) => {
      state.total_checks = action.payload
    }
  },
});

export const {
  checks,
  loaded,
  filterCheck,
  cashierDetails,
  cashierDetailsLoaded,
  receipt,
	receiptPrint,
	printingDone,
  totalChecks
} = checkSlice.actions;
export default checkSlice.reducer;
