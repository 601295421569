import api from "../../utils/api";
import { btnLoading, loading, error } from "../reducers/r_main";
import {
  checks,
  loaded,
  cashierDetails,
  receipt,
  receiptPrint,
  printingDone,
  totalChecks,
} from "../reducers/r_checks";

export const getChecks = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  dispatch(loaded());
  try {
    const response = await api.get(`check/${data.check_num}/${data.distinct}`);
    dispatch(checks(response.data));
    dispatch(loaded(true));
  } catch (err) {
    console.error(err.response.data);
  } finally {
    dispatch(btnLoading());
  }
};

export const updateChecks = (data) => async (dispatch) => {
  dispatch(receipt());
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    const response = await api.put(`/check/${data.id}`, data);
    dispatch(receipt(response.data));
    dispatch(receiptPrint(true));
    setTimeout(() => {
      try {
        window.print();
        setTimeout( () => window.print(), 2000)
      } catch (err) {
      } finally {
        dispatch(printingDone(true));
      }
    }, [1000]);
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const createCheck = (data) => async (dispatch) => {
  dispatch(receipt());
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    const response = await api.post("/check", data);
    dispatch(receipt(response.data));
    dispatch(receiptPrint(true));

    setTimeout(() => {
      try {
        window.print();
        setTimeout( () => window.print(), 2000)
      } catch (err) {
      } finally {
        dispatch(printingDone(true));
      }
    }, [1000]);
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const getCashierDetails = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/cashier-details-to-pay");
    dispatch(cashierDetails(response.data));
  } catch (err) {
    console.err(err);
  } finally {
    dispatch(loading());
  }
};

export const getTotalChecks = (cashier) => async (dispatch) => {
  try {
    const response = await api.get(`/total-checks/${cashier}`);
    dispatch(totalChecks(response.data));
  } catch (err) {
    console.error(err);
  }
};

export const closeCashier = () => async (dispatch) => {
  try {
    const response = await api.get("/transaction/close-cashier");
    if (response.status === 200) {
      window.location.reload();
    }
  } catch (err) {
    console.error(err);
  }
};
