/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Select from "react-select";
import { getRecords } from "../../state/actions/_whitelist";
import { useDispatch, useSelector } from "react-redux";

export default function Selector({ onChange, open, value }) {
  const dispatch = useDispatch();
  const whitelist = useSelector((state) => state.whitelist);
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState(0);
  // const [modal, setModal] = useState(false)

  useEffect(() => {
    if (!whitelist.loaded) {
      dispatch(getRecords());
    }
  }, [dispatch, whitelist.loaded]);

  useEffect(() => {
    if (whitelist.loaded) {
      let arreglo = [{ value: "", label: "Empty" }];
      whitelist.whitelist.map((item, key) =>
        arreglo.push({ value: item.id, label: item.name, color: item.flag })
      );
      if (value) {
        let index_selected = arreglo.findIndex((item) => item.value === value);
        setSelected(index_selected);
      }
      console.log("Selected", selected);
      setOptions(arreglo);
    }
  }, [whitelist.loaded]);

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      border: "1px solid #000",
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 15,
      width: 15,
    },
  });

  // console.log("options", options);

  return (
    options && (
      <>
        <div className="w-full flex justify-start items-center space-x-2">
          <div className="w-11/12">
            <Select
              className="basic-single"
              classNamePrefix="Whitelist"
              defaultValue={options[selected] || options[0]}
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              name="whitelist"
              options={options}
              onChange={onChange}
              styles={{
                singleValue: (styles, { data }) => ({
                  ...styles,
                  ...dot(data.color),
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  const color = data.color;
                  return {
                    ...styles,
                    color: color === "yellow" ? "#D49600" : color,
                  };
                },
              }}
            />
          </div>
          <i
            className="w-1/12 fa-solid fa-plus cursor-pointer"
            onClick={open}
          />
        </div>
      </>
    )
  );
}
