/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Submit, SubmitAction } from "../components/forms";
import Loading from "../components/Loading";
import { updateChecks } from "../../state/actions/_checks";
import Report from "./report";
import Printing from "./printing";
import Selector from "../whitelist/selector";
import CreateFromSelector from "../whitelist/create-from-selector";
import CurrencyInput from "react-currency-input-field";

export default function Form({ withdrawalModal }) {
  const dispatch = useDispatch();
  const check = useSelector((state) => state.check);
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);
  const [data, setData] = useState();
  const [percentage, setPercentage] = useState();
  const [status, setStatus] = useState(0);
  const [modalAddClient, setModalAddClient] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setData(check.checks[0]);
      setStatus(1);
    }, 500);
  }, [check.checks]);

  useEffect(() => {
    let result = config.config.filter((item) => item.code == "percentage");
    setPercentage(result[0].value);
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateChecks(data));
  };

  useEffect(() => {
    if (status == 1 && data.gross && data.taxes) {
      // console.log("entramos en 1");
      setData({
        ...data,
        // net: (data.gross - data.taxes).toFixed(2),
        net: data.net,
      });
      setStatus(2);
    }
  }, [status]);

  useEffect(() => {
    if (status == 2) {
      setData({
        ...data,
        total_fee: (data.net * (percentage / 100)).toFixed(2),
        total_to_pay: (data.net - data.net * (percentage / 100)).toFixed(2),
      });
      setStatus(3);
    }
  }, [status]);

  useEffect(() => {
    if (status == 3) {
      setData({
        ...data,
        total_fee: (data.net * (percentage / 100)).toFixed(2),
        total_to_pay: (data.net - data.net * (percentage / 100)).toFixed(2),
      });
    }
  }, [status, data?.net]);

  const changeSelector = (event) => {
    setData({
      ...data,
      whitelist: event.value,
      company_ticket: event.label,
      company_color: event.color
    });
  };

  const handleUserKeyPress = event => {
    const { key, keyCode } = event;
    if (keyCode === 123) {
      alternativePrint()
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const alternativePrint = () => {
    dispatch(updateChecks(data));
  }

  console.log("data",data);

  return (
    <>
      <div>
        <Printing  total={data?.total_to_pay} />
        {status != 3 ? (
          <Loading />
        ) : (
          <div className="flex justify-between mb-96 relative">
            <form
              action=""
              className="w-4/12 mx-auto mt-10 print:hidden mb-96"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col justify-between">
                <TextField
                  type="text"
                  id="check_num"
                  value={data.check_num}
                  disabled={false}
                  // onChange={handleChange}
                  // label="Check Number"
                  error={main.error}
                  readonly={true}
                />
                <TextField
                  type="text"
                  id="employee"
                  value={data.employee}
                  disabled={false}
                  onChange={handleChange}
                  // label="Employee"
                  error={main.error}
                  readonly={false}
                />
                {/* <TextField
              type="text"
              id="ssn"
              value={data.ssn}
              disabled={false}
              onChange={handleChange}
              label="SSN"
              error={main.error}
              readonly={false}
            /> */}
                {/* <TextField
                  type="text"
                  id="net"
                  value={data.net}
                  disabled={false}
                  onChange={handleChange}
                  label="Net"
                  error={main.error}
                  readonly={false}
                /> */}
                <div className="flex flex-col w-full mb-3">
                  <CurrencyInput
                    id="net"
                    name="net"
                    defaultValue={data.net}
                    placeholder="Valor neto"
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      setData({ ...data, [name]: value })
                    }
                    className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                    prefix="$"
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegativeValue={false}
                    allowDecimals={true}
                    groupSeparator=","
                  />
                  {main.error.errors &&
                    main.error?.errors["net"] &&
                    main.error?.errors["net"][0] && (
                      <div className="w-ful text-red-500 text-sm">
                        {main.error?.errors["net"][0]}
                      </div>
                    )}
                </div>
              </div>

              <div className="flex flex-col justify-between ">
                {/* <TextField
              type="text"
              id="check_id"
              value={data.check_id}
              disabled={true}
              // onChange={handleChange}
              label="Check Id"
              error={main.error}
              readonly={false}
            /> */}

                {/* <TextField
              type="text"
              id="gross"
              value={data.gross}
              disabled={false}
              onChange={handleChange}
              label="Gross"
              error={main.error}
              readonly={false}
            /> */}

                {/* <TextField
              type="text"
              id="taxes"
              value={data.taxes}
              disabled={false}
              onChange={handleChange}
              label="Taxes"
              error={main.error}
              readonly={false}
            /> */}
              </div>

              <div className="flex flex-col justify-between pb-3">
                {/* <TextField
              type="text"
              id="status"
              value={data.status}
              disabled={false}
              // onChange={handleChange}
              label="Status"
              error={main.error}
              readonly={true}
            /> */}
                {percentage && (
                  <TextField
                    type="text"
                    id="company_fee"
                    value={`${percentage}%`}
                    disabled={false}
                    // onChange={handleChange}
                    // label="Company Fee"
                    error={main.error}
                    readonly={true}
                  />
                )}
                {/* <TextField
                type="text"
                id="total_fee"
                value={data.total_fee}
                disabled={false}
                // onChange={handleChange}
                label="Total Fee"
                error={main.error}
                readonly={true}
              />
              <TextField
                type="text"
                id="total_to_pay"
                value={data.total_to_pay}
                disabled={false}
                // onChange={handleChange}
                label="Total to pay"
                error={main.error}
                readonly={true}
              /> */}
                <div className="flex flex-col w-full mb-3">
                  {/* <label className="font-bold text-gray-500">Total Fee:</label> */}
                  <input
                    className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                    type="text"
                    name="total_fee"
                    id="total_fee"
                    value={data.total_fee}
                    readOnly={true}
                  />
                </div>

                <div className="flex flex-col w-full mb-1">
                  {/* <label className="font-bold text-gray-500">
                    Total to pay:
                  </label> */}
                  <input
                    className="w-full bg-green-100 border-green-300 border p-2 focus:outline-none focus:ring-2"
                    type="text"
                    name="total_to_pay"
                    id="total_to_pay"
                    value={data.total_to_pay}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="flex justify-between flex-col pb-3">
                <div className="w-full">
                  <TextField
                    type="text"
                    id="picked_up_by"
                    disabled={false}
                    onChange={handleChange}
                    label=""
                    placeholder="Nombre de quien retira"
                    error={main.error}
                    readonly={false}
                  />
                </div>
                <div className="w-full">
                  <TextField
                    type="text"
                    id="phone"
                    disabled={false}
                    onChange={handleChange}
                    label=""
                    placeholder="Teléfono"
                    error={main.error}
                    readonly={false}
                  />
                </div>
                <div className="w-full">
                  <Selector
                    onChange={changeSelector}
                    open={() => setModalAddClient(true)}
                    value={data.whitelist_id}
                  />
                </div>
              </div>

              {/* <div className="flex justify-center">
              {data.net > 0  && (
                <Submit
                  text="Imprimir"
                  icon="fa-solid fa-print"
                  state={main.btnLoading}
                />)}
              </div> */}
            </form>
            <div className="w-8/12 px-10 print:w-full print:px-0">
              {!withdrawalModal && <Report data={data} ticket="PF Staffing" />}
            </div>
            <div className="absolute top-0 right-0 bg-red-200 print:hidden">
                {data.net > 0  && (
                  <SubmitAction
                    text="Imprimir"
                    icon="fa-solid fa-print"
                    state={main.btnLoading}
                    action={alternativePrint}
                  />
                )}
              </div>
          </div>
        )}
      </div>
      <CreateFromSelector
        modal={modalAddClient}
        close={() => setModalAddClient(false)}
      />
    </>
  );
}
