import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getRecords as getCashiers } from "../../state/actions/_cashier";
import { getRecords as getConfig } from "../../state/actions/_config";
import Loading from "../components/Loading";
import List from "./list";
import { success } from '../../state/reducers/r_main'


export default function Cashier() {
  const dispatch = useDispatch();
  const cashier = useSelector((state) => state.cashier);
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);

  useEffect( () => {
    if (main.success.status) {
      dispatch(success());
    }
  },[main.success.status, dispatch]);

  useEffect(() => {
    if (!cashier.loaded) {
      dispatch(getCashiers());
    }
  }, [dispatch, cashier.loaded]);

  useEffect(() => {
    dispatch(success());
    if (!config.loaded) {
      dispatch(getConfig());
    }
  }, [dispatch, config.loaded]);

  return main.loading ? (
    <Loading />
  ) : (
    <>
      <Routes>
        {["", ":method", ":method/:key"].map(
          (path, key) => (
            <Route key={key} path={path} element={<List />} />
          )
        )}
      </Routes>
    </>
  );
}
