import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal";
import Receipt from "./receipt";
// import ReceiptToPrint from './to-print'
import { TextField, Submit } from "../../components/forms";
import { createWithdrawal } from "../../../state/actions/_transactions";
import Loading from "../../components/Loading";
import { errorTran } from "../../../state/reducers/r_transaction";
import CurrencyInput from "react-currency-input-field";

export default function Withdrawal(props) {
  const { close, status, cashier, amount } = props;
  const [data, setData] = useState({
    cashier_id: cashier.id,
  });
  const main = useSelector((state) => state.main);
  const tran = useSelector((state) => state.tran);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  useEffect(() => {
    dispatch(errorTran());
  }, [dispatch]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createWithdrawal(data, handleError, handleSuccess));
  };

  const handleError = () => {
    setStep(0);
  };

  const handleSuccess = () => {
    setStep(1);
  };

  return (
    <>
      <Modal
        size="w-8/12"
        title={`${cashier.name} - $${amount}`}
        state={status}
        close={close}
      >
        <div className="flex justify-between">
          <div className="w-1/2 px-10 lg:px-20">
            {step === 0 && (
              <form
                action=""
                className="w-full mx-auto"
                onSubmit={handleSubmit}
              >
                {/* <TextField
                  type="text"
                  id="amount"
                  value=""
                  disabled={false}
                  onChange={handleChange}
                  label="Amount"
                  error={tran.error}
                  readonly={false}
                /> */}
                <div className="flex flex-col w-full mb-3">
                  <label htmlFor="amount" className="font-bold text-gray-500">
                    Amount:
                  </label>
                  <CurrencyInput
                    id="amount"
                    name="amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      setData({ ...data, [name]: value })
                    }
                    className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
                    prefix="$"
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegativeValue={false}
                    allowDecimals={true}
                    groupSeparator=","
                  />
                  {tran.error.errors &&
                    tran.error?.errors["amount"] &&
                    tran.error?.errors["amount"][0] && (
                      <div className="w-ful text-red-500 text-sm">
                        {tran.error?.errors["amount"][0]}
                      </div>
                    )}
                </div>
                <TextField
                  type="text"
                  id="concept"
                  value=""
                  disabled={false}
                  onChange={handleChange}
                  label="Description"
                  error={tran.error}
                  readonly={false}
                />
                <div className="pt-3 flex justify-center">
                  <Submit
                    text="Complete"
                    icon="fa-solid fa-check"
                    state={main.btnLoading}
                  />
                </div>
              </form>
            )}
            {step === 1 && (
              <>
                <Loading />
                <div className="font-bold text-green-800 text-center">
                  Printing Receipt...
                </div>
              </>
            )}
          </div>
          <div className="w-1/2 pr-20">
            <Receipt hidden={false} data={data} />
          </div>
        </div>
      </Modal>
      <Receipt hidden={true} data={data} />
    </>
  );
}
