import { configureStore } from "@reduxjs/toolkit";
import main from './reducers/r_main';
import auth from './reducers/r_auth';
import user from './reducers/r_users';
import role from './reducers/r_roles';
import config from './reducers/r_config';
import cashier from './reducers/r_cashier';
import check from './reducers/r_checks';
import tran from './reducers/r_transaction'
import whitelist from './reducers/r_whitelist'

export default configureStore({
	reducer: {
		main: main,
		auth: auth,
		user: user,
		role: role,
		config: config,
		cashier: cashier,
		check: check,
		tran: tran,
		whitelist: whitelist
	}
})