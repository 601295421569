import { createSlice } from "@reduxjs/toolkit";

const whitelistSlice = createSlice({
	name: 'whitelist',
	initialState: {
		whitelist: [],
		loaded: false,
	},
	reducers: {
		whitelist: (state, action) => {
			state.whitelist = action.payload;
			state.loaded = true;
		},
		loaded: (state, action) => {
			state.loaded = action.payload ? action.payload : false
		},
	}
})

export const { whitelist, loaded, } = whitelistSlice.actions;
export default whitelistSlice.reducer;