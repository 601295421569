// import { useState, useEffect } from 'react';
// import { useSelector } from "react-redux";
import Moment from "react-moment";

export default function Report({ data, ticket }) {
  // const receipt = useSelector((state) => state.check);
  // const [data, setData] = useState(undefined);

  // useEffect( () => {
  //   setData(receipt.receipt);
  // },[receipt.receipt])

  // console.log(data)

  return (
    data && (
      <div className="print:block w-full print:text-xs text-lg">
        <div className="w-full mx-auto">
          <img
            className="w-1/2 mx-auto"
            src={require("../../assets/img/SmartCash_Logo_HD.png")}
            alt=""
          />
          <div className="w-full text-center mb-5 text-xs">
            904 Main Street, Pleasent Grove, UT 84062
          </div>
        </div>
        <div className="text-center uppercase font-bold">Receipt</div>
        <table className="report">
          <tbody>
            <tr>
              <th className="w-4/12 print:w-1/2">Date:</th>
              <td className="flex flex-col items-end">
                {/* <Moment format="MM/DD/YYYY">{data.updated_at}</Moment>
                <Moment format="hh:mm:ss">{data.updated_at}</Moment> */}
                <Moment format="MM/DD/YYYY" />
                <Moment format="hh:mm:ss" />
              </td>
            </tr>
            <tr>
              <th>Name:</th>
              <td>{data.employee}</td>
            </tr>
            <tr>
              <th>Phone Number:</th>
              <td>{data.phone}</td>
            </tr>
            <tr>
              <th>Company Name:</th>
              <td>{data.company_ticket || ticket }</td>
            </tr>
            <tr>
              <th>Check Number:</th>
              <td>{data.check_num}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>${data.net}</td>
            </tr>
            <tr>
              <th>Fee {(data.company_fee > 0) ? data.company_fee : 1}%</th>
              <td>${data.total_fee}</td>
            </tr>
            <tr>
              <th className="pt-2">Total Amount:</th>
              <td className="print:text-lg text-3xl font-extrabold text-green-800">
                ${data.total_to_pay}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full text-justify text-[10px] mt-2">
          <span className="font-bold mr-2">WARNING!</span> Presenting a fraudulent instrument or note carries serious
          legal consequences. If you knowingly or intentionally present a
          fraudulent instrument or note you may be liable for court costs,
          collection costs, attorney fees, and interest at a 20% monthly
          interest.
        </div>
        <div className="w-full flex justify-end space-x-3 mt-5">
          <span className="w-1/2 text-right">Signature</span>
          <span className="w-1/2 border-b-2 border-gray-400"></span>
        </div>
      </div>
    )
  );
}
