import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { loginAction } from "../../state/actions/_auth";
import { Submit, TextField, SelectField } from "../components/forms";
import { getRecords } from "../../state/actions/_cashier";
import Loading from '../components/Loading'
import { Error } from '../components/alerts';
import { error } from '../../state/reducers/r_main'

export default function Login() {
  const dispatch = useDispatch();
  const r_auth = useSelector((state) => state.auth);
  const main = useSelector((state) => state.main);
  const cashier = useSelector((state) => state.cashier);
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    dispatch(getRecords());
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(loginAction(data));
  };

  // console.log(main);

  return (
    <>
      {r_auth.auth && <Navigate to="/" />}
      {cashier.cashier.length === 0 ? (
        <Loading />
      ) : (
        <>
        {/* <div>default: publio.quintero@gmail.com adminroot01</div> */}
        <form
          action=""
          onSubmit={handleSubmit}
          className="w-1/3 mx-auto bg-gray-100 p-10 rounded border-r-4 border-b-4 mt-10 "
        >
          <div className="my-5"><Error state={main.error.status} message={main.error.message} close={ () => dispatch(error())} /></div>
          <SelectField
            id="cashier_id"
            value=""
            disabled={false}
            onChange={handleChange}
            label="Caja"
            options={cashier.cashier}
            error={main.error}
          />
          <div>
            <TextField
              type="text"
              id="username"
              value={data.username}
              onChange={handleChange}
              label="Username"
              error={main.error}
            />
          </div>
          <div>
            <TextField
              type="password"
              id="password"
              value={data.password}
              onChange={handleChange}
              label="Password"
              error={main.error}
            />
          </div>
          <div className="flex justify-center mt-10">
            <Submit
              text="Entrar"
              icon="fa-solid fa-sign-in-alt"
              state={r_auth.btnLoading}
            />
          </div>
        </form>
        </>
      )}
    </>
  );
}
