export const Error = ({ state, message, close }) => {
  return (
    state && (
      <div className="print:hidden bg-red-200 text-red-600 border border-red-300 py-2 px-3 flex justify-between">
        {message}
        <i className="fa-solid fa-times cursor-pointer hover:scale-105 active:scale-95" onClick={close} />
      </div>
    )
  );
};

export const Success = ({ state, message, close }) => {
  return (
    state && (
      <div className="bg-green-200 text-green-600 border border-green-300 py-2 px-3 flex justify-between">
        <span>{message}</span>
        <i className="fa-solid fa-times cursor-pointer" onClick={close} />
      </div>
    )
  );
};

export const Info = ({ state, message }) => {
  return (
      <div className="bg-blue-100 text-blue-900  border border-blue-300 py-2 px-3 mt-10 w-11/12 mx-auto">
        {message}
      </div>
    )
};
