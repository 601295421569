import { useEffect, useState } from "react";
import { SelectField, Submit, TextField } from "../../components/forms";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../state/actions/_cashier";
import { getDetails } from "../../../state/actions/_transactions";
import moment from "moment";

export default function Balance() {
  const cashier = useSelector((state) => state.cashier);
  const history = useSelector((state) => state.tran);
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const [data, setData] = useState({
    cashier_id: 1,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [filter, setFilter] = useState({});
  const [title, setTitle] = useState("Todas las transacciones");

  useEffect(() => {
    if (!cashier.loaded) dispatch(getRecords());
  }, [cashier.loaded, dispatch]);

  useEffect(() => {
    // console.log(history.history);
    if (
      Object.entries(history.history).length > 0 &&
      history.history.details.length > 0
    ) {
      setFilter(history.history.details);
    }
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getDetails(data));
  };

  const filterTransactions = (filter_param, filter_alias) => {
    const totals = history.history.details.filter(
      (item) => item.type === filter_param
    );
    //console.log(filter_param);
    setTitle(filter_alias || "Todas las transacciones");
    setFilter(totals);
  };

  //console.log(filter);

  return (
    data && (
      <div>
        <h1 className="w-10/12 font-bold mx-auto underline mb-5">
          Detalles de Transacciones
        </h1>
        <form
          action=""
          onSubmit={handleSubmit}
          className="w-10/12 mx-auto flex space-x-3 justify-center items-center"
        >
          <SelectField
            id="cashier_id"
            value={data.cashier_id}
            disabled={false}
            onChange={handleChange}
            label="Filter by Cashier"
            error={main.error}
            options={cashier.cashier}
          />
          <TextField
            type="date"
            id="start_date"
            value={data.start_date}
            disabled={false}
            onChange={handleChange}
            label="Fecha de Inicio"
            error={main.error}
            readonly={false}
          />
          <TextField
            type="date"
            id="end_date"
            value={data.end_date}
            disabled={false}
            onChange={handleChange}
            label="Fecha Fin"
            error={main.error}
            readonly={false}
          />
          <div className="pt-3">
            <Submit
              text="Buscar"
              icon="fa-solid fa-search"
              state={main.btnLoading}
            />
          </div>
        </form>
        {Object.entries(history.history).length > 0 && (
          <div className="w-10/12 mx-auto mt-5">
            <div className="w-9/12 mx-auto">
              {history.history.totals.length > 0 && (
                <table className="w-11/12 mx-auto">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left py-2">Operación</th>
                      <th className="text-left">Monto</th>
                      <th className="text-left">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.history.totals.map((item, index) => (
                      <tr key={index} className="border-b hover:bg-gray-200">
                        <td className="py-2">{item.alias}</td>
                        <td>${Number.parseFloat(item.total).toFixed(2)}</td>
                        <td>
                          <i
                            className="fa-solid fa-arrow-right cursor-pointer"
                            onClick={() =>
                              filterTransactions(item.name, item.alias)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="w-full mt-5">
              <h2 className="font-bold text-lg border mt-10 mb-5 bg-blue-100 p-2 border-blue-500">
                {title}
              </h2>
              {filter.length > 0 ? (
                <table className="w-full mx-auto mb-10">
                  <thead>
                    <tr className="border-b bg-black text-white">
                      {/* <th className="text-left py-2">Operación</th> */}
                      <th className="text-left py-2 pl-2">No</th>
                      <th className="text-left">Origen</th>
                      <th className="text-left">Destino</th>
                      <th className="text-left">Usuario</th>
                      <th className="text-left">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filter.map((item, index) => (
                      <tr key={index} className="border-b hover:bg-gray-200">
                        {/* <td className="py-2">{item.type}</td> */}
                        <td className="py-1 pl-2">{index + 1}</td>
                        <td>
                          {item.type === "withdrawal" && item.cashier.name}
                          {item.type === "main_to_cashier" && "Caja Principal"}
                          {item.type === "cashier_to_cashier" &&
                            item.from_cashier.name}
                          {item.type === "cashier_to_customer" &&
                            item.cashier.name}
                        </td>
                        <td>
                          {item.type === "withdrawal" && item.concept}
                          {item.type !== "withdrawal" &&
                            item.type !== "cashier_to_customer" &&
                            item.cashier.name}
                          {item.type === "cashier_to_customer" && (
                            <>
                              {item.check.employee} - {item.check.check_num}
                            </>
                          )}
                        </td>
                        <td>{item.user.name}</td>
                        <td>${Number.parseFloat(item.amount).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="w-full p-2 border border-red-700 bg-red-200 text-red-600">
                  No Transactions
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
}
