import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modal";
import { modal } from "../../state/reducers/r_main";
import { getPermissions, updatePermission } from "../../state/actions/_roles";
import { permissions } from "../../state/reducers/r_roles";
import Loading from "../components/Loading";

export default function Update({ ind }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const main = useSelector((state) => state.main);
  const role = useSelector((state) => state.role);
  const [data, setData] = useState();

  const close = () => {
    dispatch(modal());
    navigate("/roles");
  };

  useEffect(() => {
    if (role.loaded) {
      setData(role.roles[ind]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, role.loaded, ind]);

  useEffect(() => {
    if (data) {
      dispatch(getPermissions(data.id));
    }
    return () => {
      dispatch(permissions([]));
    };
  }, [data]);

  const handleChange = (module_id, event) => {
    let checkedPermission = event.target.checked;
    dispatch(updatePermission(checkedPermission, module_id, data.id));
  };

  return (
    <>
      <Modal
        size="w-1/2"
        title={`Update Permissions ${data && data.name}`}
        state={main.modal}
        close={close}
      >
        {role.roleLoading && <Loading />}
        {data && !role.roleLoading && (
          <table className="table">
            <thead>
              <tr>
                <th>Module</th>
                <th>Route</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {role.permissions &&
                role.permissions.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <i className={item.icon} /> {item.name}
                    </td>
                    <td>{item.url}</td>
                    <td>
                      <input
                        className="w-4 h-4"
                        type="checkbox"
                        defaultChecked={
                          item.permissions && item.permissions.length > 0
                            ? item.permissions[0].status
                            : false
                        }
                        onChange={(event) => handleChange(item.id, event)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Modal>
    </>
  );
}
