import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getRecords } from "../../state/actions/_users";
import { getRecords as getRoles } from '../../state/actions/_roles'
import Loading from "../components/Loading";
import List from "./list";


export default function Users() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const main = useSelector((state) => state.main);
  const role = useSelector(state => state.role);

  useEffect(() => {
    if (!user.loaded) {
      dispatch(getRecords());
    }
  }, [dispatch, user.loaded]);

  useEffect( () => {
    if (!role.loaded) {
      dispatch(getRoles());
    }
  },[dispatch, role.loaded])

  return main.loading ? (
    <Loading />
  ) : (
    <>
      <Routes>
        {["", ":method", ":method/:key"].map(
          (path, key) => (
            <Route key={key} path={path} element={<List />} />
          )
        )}
      </Routes>
    </>
  );
}
