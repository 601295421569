import Modal from "../components/modal";
import Moment from "react-moment";
import { Currency, Submit, TextField } from "../components/forms";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { updateTransaction } from "../../state/actions/_transactions";

export default function EditTransaction({ item, modal, close }) {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const [data, setData] = useState({
    net: item.net,
    total_fee: item.total_fee,
    total_to_pay: item.total_to_pay,
    company_fee: item.company_fee,
    previous_net: item.total_to_pay,
    ...item,
  });

  const updateNet = (value, name) => {
    setData({
      ...data,
      net: value,
      total_fee: (value * (data.company_fee / 100)).toFixed(2),
      total_to_pay: (value - value * (data.company_fee / 100)).toFixed(2),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateTransaction(data, close));
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  // console.log(item);

  return (
    <Modal size="w-1/2" title="Edit Transaction" state={modal} close={close}>
      {item && (
        <form action="" onSubmit={handleSubmit}>
          <table className="details">
            <tbody>
              <tr>
                <th>Check Number:</th>
                <td className="pt-2 pb-0 pr-2">
                  <TextField
                    type="text"
                    id="check_num"
                    value={item.check_num}
                    disabled={false}
                    onChange={handleChange}
                    error={main.error}
                    readonly={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Employee:</th>
                <td className="pt-2 pb-0 pr-2">
                  <TextField
                    type="text"
                    id="employee"
                    value={item.employee}
                    disabled={false}
                    onChange={handleChange}
                    error={main.error}
                    readonly={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Net:</th>
                <td className="pt-2 pb-2 pr-2">
                  <Currency
                    value={item.net}
                    onChange={(value, name) => updateNet(value, name)}
                    id="net"
                    error={main.error}
                    label=""
                  />
                </td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{item.status}</td>
              </tr>
              <tr>
                <th>Company Fee:</th>
                <td>{item.company_fee}%</td>
              </tr>
              <tr>
                <th>Total Fee:</th>
                <td>${data.total_fee}</td>
              </tr>
              <tr>
                <th>Total To Pay:</th>
                <td>${data.total_to_pay}</td>
              </tr>
              {/* <tr>
					<th>Receipt URL:</th>
					<td><a className='text-blue-400' target="_blank"  href={`${process.env.REACT_APP_URL}/${details.receipt_url}`} rel="noreferrer">{details.receipt_url}</a></td>
				</tr> */}
              <tr>
                <th>Picked Up By:</th>
                <td className="pt-2 pb-0 pr-2">
                  <TextField
                    type="text"
                    id="picked_up_by"
                    value={item.picked_up_by}
                    disabled={false}
                    onChange={handleChange}
                    error={main.error}
                    readonly={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Phone:</th>
                <td className="pt-2 pb-0 pr-2">
                  <TextField
                    type="text"
                    id="phone"
                    value={item.phone}
                    disabled={false}
                    onChange={handleChange}
                    error={main.error}
                    readonly={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Company Name:</th>
                <td>
                  <span
                    className="w-5 h-5 rounded-full mr-2 inline-block align-middle"
                    style={{ backgroundColor: item.client?.flag }}
                  ></span>
                  {item.client?.name}
                </td>
              </tr>
              <tr>
                <th>Cashier</th>
                <td>{item.transaction?.cashier?.name}</td>
              </tr>
              <tr>
                <th>Fecha y Hora</th>
                <td>
                  <Moment format="MM/DD/YYYY hh:mm a">{item.transaction?.updated_at}</Moment>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-center mt-5">
            <Submit
              text="Actualizar"
              icon="fa-solid fa-edit"
              state={main.btnLoading}
              type="submit"
            />
          </div>
        </form>
      )}
    </Modal>
  );
}
