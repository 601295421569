import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: 'user',
	initialState: {
		users: [],
		loaded: false
	},
	reducers: {
		users: (state, action) => {
			state.users = action.payload;
			state.loaded = true;
		},
		loaded: (state, action) => {
			state.loaded = action.payload ? action.payload : false
		}
	}
})

export const { users, loaded } = userSlice.actions;
export default userSlice.reducer;