import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Update from "./update";
import ToMain from "../transactions/to-main";
import { Link } from "react-router-dom";
import { Info } from "../components/alerts";
import { modal } from "../../state/reducers/r_main";

export default function List() {
  let { method, key } = useParams();
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);

  const activateModal = () => {
    dispatch(modal(true));
  };

  return (
    <>
      <div className="mt-10">
        <div className="w-11/12 mx-auto flex justify-between items-center">
          <h1 className="underline font-bold capitalize text-xl">
            Configuration
          </h1>
          <div className="flex justify-end items-center">
            <Link
              to="/config/to-main"
              className="submenu"
              onClick={activateModal}
            >
              <i className="fa-solid fa-plus" /> Nueva Transacción
            </Link>
          </div>
        </div>
        {config.config ? (
          <div className="w-11/12 mx-auto">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Code</th>
                  <th>Value</th>
                  <th>Updated at</th>
                  <th>...</th>
                </tr>
              </thead>
              <tbody>
                {config.config.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.code}</td>
                    <td>
                      {item.code === "main_cashier"
                        ? Intl.NumberFormat("us-US").format(
                            parseFloat(item.value).toFixed(2)
                          )
                        : item.value}
                    </td>
                    <td>
                      <Moment fromNow>{item.updated_at}</Moment>
                    </td>
                    <td className="options">
                      {item.code !== "main_cashier" ? (
                        <Link
                          to={`/config/update/${ind}`}
                          onClick={activateModal}
                        >
                          <i className="fa-solid fa-edit edit" />
                        </Link>
                      ) : (
                        <i className="fa-solid fa-edit inactive" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Info message="There is no records." />
        )}
      </div>
      {method === "update" && <Update ind={key} />}
      {method === "to-main" && <ToMain />}
    </>
  );
}
