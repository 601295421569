import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Results from "./results";
import Withdrawal from "./withdrawal/withdrawal";
import { TextField, Submit } from "../components/forms";
import { Error } from "../components/alerts";
import {
  getChecks,
  getCashierDetails,
  getTotalChecks,
  closeCashier,
} from "../../state/actions/_checks";
import { getRecords as getConfig } from "../../state/actions/_config";
import { checks } from "../../state/reducers/r_checks";
import { error } from "../../state/reducers/r_main";
// import Report from "./report";
import Deposit from "./deposit/deposit";
import Transfer from "./transfer/transfer";

export default function Checks() {
  const [data, setData] = useState({
    distinct: true,
  });
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);
  const check = useSelector((state) => state.check);
  const config = useSelector((state) => state.config);
  const [status, setStatus] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(checks({}));
  }, [dispatch]);

  // * * Using config to calculate taxes and fees
  useEffect(() => {
    if (!config.loaded) {
      dispatch(getConfig());
    }
  }, [dispatch, config.loaded]);

  useEffect(() => {
    if (!check.cashier_details_loaded) {
      dispatch(getCashierDetails());
      setStatus(1);
    }
  }, [dispatch, check.cashier_details_loaded]);

  useEffect(() => {
    if (status === 1 && check.cashier_details?.id) {
      dispatch(getTotalChecks(check.cashier_details.id));
    }
  }, [dispatch, status, check.cashier_details?.id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(data);
    dispatch(error());
    dispatch(getChecks(data));
  };

  const cerrarCaja = () => {
    if (window.confirm("¿Desea confirmar el cierre de la caja?")) {
      dispatch(closeCashier());
    }
  };

  // console.log(check.cashier_details);
  // console.log(modal2)

  return (
    <div className="w-11/12 mx-auto mt-10">
      {check.cashier_details && (
        <>
          <div className="flex justify-between items-center mb-5">
            <div
              className={`print:hidden ${
                check.cashier_details.amount <= 2000
                  ? "bg-red-100 border-red-300 text-red-900"
                  : "bg-blue-100 border-blue-300 text-blue-900"
              }  border p-2 w-1/2 `}
            >
              {check.cashier_details.amount <= 2000 && (
                <i className="fa-solid fa-triangle-exclamation mr-2" />
              )}
              <span className="font-bold">{check.cashier_details.name}</span>
              {" / "}
              <span className="font-bold">
                ${check.cashier_details.amount}
              </span>{" "}
              disponible.
            </div>
            <div className="print:hidden bg-blue-100 border border-blue-300 p-2 w-1/2 ml-1 text-blue-900">
              <span className="font-bold">{check.total_checks}</span> cheques
              emitidos
            </div>
            <div className="w-1/2">
              <button
                className="print:hidden bg-gray-700 p-2 rounded text-white ml-1 hover:opacity-80"
                onClick={cerrarCaja}
              >
                Cerrar Caja
              </button>
              <button
                className="print:hidden bg-gray-700 p-2 rounded text-white ml-1 hover:opacity-80"
                onClick={() => setModal2(true)}
              >
                Depósito
              </button>
              <button
                className="print:hidden bg-gray-700 p-2 rounded text-white ml-1 hover:opacity-80"
                onClick={() => setModal(true)}
              >
                Retiros
              </button>
              <button
                className="print:hidden bg-gray-700 p-2 rounded text-white ml-1 hover:opacity-80"
                onClick={() => setModal3(true)}
              >
                Transferir
              </button>
            </div>
          </div>
          {modal && (
            <Withdrawal
              close={() => setModal(false)}
              status={modal}
              cashier={check.cashier_details}
              amount={check.cashier_details.amount}
            />
          )}
          {modal2 && (
            <Deposit
              close={() => setModal2(false)}
              status={modal2}
              cashier={check.cashier_details}
            />
          )}
          {modal3 && (
            <Transfer
              close={() => setModal3(false)}
              status={modal3}
              cashier={check.cashier_details}
            />
          )}
        </>
      )}
      <form
        action=""
        onSubmit={handleSubmit}
        className="flex items-center w-1/2 mx-auto space-x-2 print:hidden"
      >
        <div className="pt-3 w-1/2">
          <TextField
            type="text"
            id="check_num"
            value=""
            disabled={false}
            onChange={handleChange}
            label="Número de cheque"
            error={main.error}
            readonly={false}
          />
          <div className="flex items-center space-x-3">
            <input
              type="checkbox"
              id="distinct"
              name="distinct"
              className="w-5 h-5"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.checked })
              }
              defaultChecked
            />
            <label htmlFor="distinct" className="text-gray-500">Agrupar resultados</label>
          </div>
        </div>
        <div className="">
          <Submit
            text="Buscar"
            icon="fa-solid fa-search"
            state={main.btnLoading}
          />
        </div>
      </form>
      <div className="w-1/2 mx-auto mt-5">
        <Error
          state={main.error.status}
          message={main.error.message}
          close={() => dispatch(error())}
        />
      </div>
      {check.loaded && <Results checkData={data} withdrawalModal={modal} />}
    </div>
  );
}
