import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Create from "./create";
import Update from "./update";
import Delete from "./delete";
import { Link } from "react-router-dom";
import { Info } from "../components/alerts";
import { modal } from "../../state/reducers/r_main";

export default function List() {
  let { method, key } = useParams();
  const dispatch = useDispatch();
  const whitelist = useSelector((state) => state.whitelist);

  const activateModal = () => {
    dispatch(modal(true));
  };

  return (
    <>
      <div className="mt-10">
      <div className="w-11/12 mx-auto flex justify-between items-center">
          <h1 className="underline font-bold capitalize text-xl">Whitelist</h1>
          <div className="flex justify-end items-center">
            <Link to="/whitelist/create" className="submenu" onClick={activateModal}>
              <i className="fa-solid fa-plus"/> Add Item
            </Link>
          </div>
        </div>
        {whitelist.whitelist ? (
          <div className="w-11/12 mx-auto">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Flag</th>
                  <th>Updated at</th>
                  <th>...</th>
                </tr>
              </thead>
              <tbody>
                {whitelist.whitelist.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.name}</td>
                    <td><div className="border border-black w-5 h-5" style={{ backgroundColor: item.flag }}></div></td>
                    <td>
                      <Moment fromNow>{item.updated_at}</Moment>
                    </td>
                    <td className="options">
                      <Link to={`/whitelist/update/${ind}`} onClick={activateModal}>
                        <i className="fa-solid fa-edit edit" />
                      </Link>
                      <Link to={`/whitelist/delete/${ind}`} onClick={activateModal}>
                        <i className="fa-solid fa-trash delete" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Info message="There is no records." />
        )}
      </div>
      {method === "create" && <Create />}
      {method === "update" && <Update ind={key} />}
      {method === "delete" && <Delete ind={key} />}
    </>
  );
}
