import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Modal from "../components/modal";
import { TextField, Submit, SubmitGreen } from "../components/forms";
import { modal, error } from "../../state/reducers/r_main";
import { userToMain } from "../../state/actions/_transactions";
import CurrencyInput from "react-currency-input-field";

export default function ToMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    type: 'add'
  });
  const [currentAmount, setCurrentAmount] = useState();
  const main = useSelector((state) => state.main);
  const config = useSelector((state) => state.config);

  useEffect(() => {
    if (config.loaded) {
      let result = config.config.filter((item) => item.code == "main_cashier");
      if (result.length > 0) {
        setCurrentAmount(result[0].value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.loaded]);

  const closeModal = () => {
    dispatch(modal());
    dispatch(error());
    navigate("/config");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(data)
    dispatch(userToMain(data));
  };

  // console.log(currentAmount)

  return (
    <>
      {main.success.status && <Navigate to="/config" />}
      <Modal
        size="w-1/2"
        title="New transaction to main cashier"
        state={main.modal}
        close={closeModal}
      >
        <form action="" className="w-2/3 mx-auto" onSubmit={handleSubmit}>
          {/* <TextField
            size="w-1/2"
            type="text"
            value={currentAmount}
            disabled={true}
            readonly={true}
            onChange={handleChange}
            label="Current Amount"
            error={main.error}
          /> */}
          {currentAmount && 
          <div className="flex flex-col w-full mb-3">
            <label htmlFor="net" className="font-bold text-gray-500">
              Current Amount:
            </label>
            <CurrencyInput
              id="net"
              name="net"
              defaultValue={currentAmount}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                setData({ ...data, [name]: value })
              }
              disabled={true}
              className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
              prefix="$"
              decimalScale={2}
              decimalSeparator="."
              allowNegativeValue={false}
              allowDecimals={true}
              groupSeparator=","
            />
          </div>}
          <div className="flex flex-col w-full mb-3">
          <label htmlFor="net" className="font-bold text-gray-500">
              New Amount:
            </label>
            <CurrencyInput
              id="amount"
              name="amount"
              placeholder="New amount"
              decimalsLimit={2}
              onValueChange={(value, name) =>
                setData({ ...data, [name]: value })
              }
              className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
              prefix="$"
              decimalScale={2}
              decimalSeparator="."
              allowNegativeValue={false}
              allowDecimals={true}
              groupSeparator=","
            />
            {main.error.errors && main.error?.errors['amount'] && main.error?.errors['amount'][0] && (
              <div className="w-ful text-red-500 text-sm">
                {main.error?.errors['amount'][0]}
              </div>
            )}
          </div>
          {/* <TextField
            size="w-1/2"
            id="amount"
            type="text"
            value=""
            disabled={false}
            onChange={handleChange}
            label="Amount to Add"
            error={main.error}
          /> */}
          <div className="flex items-center justify-start space-x-5">
            <div>
              <input type="radio" name="type" id="add" defaultChecked className="mr-2" onChange={ () => setData({ ...data, type: 'add'})}  />
              <label htmlFor="add">Sumar</label>
            </div>
            <div>
              <input type="radio" name="type" id="restart" className="mr-2" onChange={ () => setData({ ...data, type: 'restart'})}  />
              <label htmlFor="restart">Reiniciar</label>
            </div>
          </div>
          <div className="flex justify-center mt-7 space-x-4">
            <Submit
              text="Ejecutar"
              icon="fa-solid fa-check"
              state={main.btnLoading}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
