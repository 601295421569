import api from '../../utils/api';
import { loading, btnLoading, error, modal } from '../reducers/r_main';
import { config, loaded } from '../reducers/r_config';

export const getRecords = () => async (dispatch) => {
	dispatch(loading(true));
	dispatch(error());
	try {
		const response = await api.get('configuration');
		dispatch(config(response.data));
		dispatch(error());
	} catch (err) {
		console.error(err.response.data);
	} finally {
		dispatch(loading());
	}
}

export const updateRecord = (data) => async (dispatch) => {
	dispatch(btnLoading(true));
	try {
		await api.put(`configuration/${data.id}`, data);
		dispatch(loaded());
		dispatch(modal());
		// dispatch(success({
		// 	status: true,
		// 	message: 'User updated successfully.'
		// }))
	} catch (err) {	
		dispatch(error({
			status: true,
			message: err.response.data.message,
			errors: err.response.data.errors
		}))
	} finally {
		dispatch(btnLoading())
	}
}