/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Loading from "../components/Loading";
import { Info } from "../components/alerts";
import { SelectField, Submit } from "../components/forms";
import {
  getCompleted,
  print,
  search,
  removeTransactionAction,
} from "../../state/actions/_transactions";
import { getRecords } from "../../state/actions/_cashier";
import ToPrint from "./to-print";
import Printing from "./printing";
import EditTransaction from "./edit-transaction";
import { completedLoaded } from "../../state/reducers/r_transaction";
import Details from "./history/details";

export default function Transactions() {
  const dispatch = useDispatch();
  const tran = useSelector((state) => state.tran);
  const main = useSelector((state) => state.main);
  const cashier = useSelector((state) => state.cashier);
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const [total, setTotal] = useState(undefined);
  // const [cash, setCash] = useState();
  const [rows, setRows] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [item, setItem] = useState();
  const searchValue = useRef(null);

  useEffect(() => {
    if (!cashier.loaded) dispatch(getRecords());
  }, [cashier.loaded]);

  useEffect(() => {
    if (!tran.completed_loaded) {
      dispatch(getCompleted());
    }
  }, [tran.completed_loaded]);

  useEffect(() => {
    if (tran.completed.length > 0) {
      setData(tran.completed);
      if (tran.completed.length <= 25) {
        let rows1 = 24 - tran.completed.length;
        setRows(rows1);
      }
    }
  }, [tran.completed]);

  useEffect(() => {
    if (data) {
      let result = 0;
      data.map(
        (item) =>
          (result = item.net ? parseFloat(result) + parseFloat(item.net) : 0)
      );
      setTotal(result.toFixed(2));
    }
  }, [data]);

  const handleChange = (event) => {
    let value = event.target.value;
    // setCash(value);
    if (value) {
      let filter = tran.completed.filter(
        (item) => item.transaction?.cashier.id == value
      );
      setData(filter);
    } else {
      setData(tran.completed);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(data)
    dispatch(print(data));
  };

  const openModal = (element) => {
    setItem(element);
    setModal(true);
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      // console.log("entramos aqui");
      dispatch(search({ [e.target.name]: e.target.value }));
    } else {
      setData(tran.completed);
    }
  };

  useEffect(() => {
    if (searchValue.current.value !== "" && tran.search.length > 0) {
      setData(tran.search);
    }
  }, [tran.search]);

  const handleData = (details) => {
    setData2(details);
    setModal2(true);
  };
  // console.log(searchValue.current.value);

  const removeTransaction = (item) => {
    // console.log(item)
    if (
      window.confirm(
        `¿Está seguro que desea borrar la transacción con Número de Cheque ${item.check_num} de ${item.employee}?`
      )
    ) {
      dispatch(removeTransactionAction(item));
    }
  };

  // console.log(data)

  return main.loading ? (
    <Loading />
  ) : (
    <div className="mt-10">
      <div className="flex justify-between items-center w-11/12 mx-auto print:hidden">
        <div className="w-2/5 flex space-x-1">
          <Link
            to="/history"
            className="bg-gray-600 text-white p-2 rounded text-sm hover:opacity-70"
          >
            <i className="fa-solid fa-history" /> Checks History
          </Link>
          <button
            type="button"
            className="bg-gray-600 text-white p-2 rounded text-sm hover:opacity-70"
            onClick={() => dispatch(completedLoaded())}
          >
            <i className="fa-solid fa-refresh" /> Refresh
          </button>
          <Link
            to="/balance"
            className="bg-gray-600 text-white p-2 rounded text-sm hover:opacity-70"
          >
            <i className="fa-solid fa-file" /> Reports
          </Link>
          <Link
            to="/filter"
            className="bg-gray-600 text-white p-2 rounded text-sm hover:opacity-70"
          >
            <i className="fa-solid fa-filter" /> Filter
          </Link>
        </div>
        {cashier.cashier && (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-3/5 mx-auto flex justify-end print:hidden"
          >
            <div className="w-1/3">
              <SelectField
                id="cashier"
                value=""
                disabled={false}
                onChange={handleChange}
                label="Filter by Cashier"
                error={main.error}
                options={cashier.cashier}
              />
            </div>
            <div className="pt-5 ml-2">
              <Submit
                text="Print"
                icon="fa-solid fa-print"
                state={main.btnLoading}
              />
            </div>
          </form>
        )}
      </div>
      <div className="w-11/12 mx-auto print:hidden">
        <div className="w-1/3">
          <input
            className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2"
            type="text"
            name="search"
            id="search"
            onChange={handleSearch}
            placeholder="Buscar (No Cheque o nombre)"
            ref={searchValue}
          />
        </div>
      </div>
      {data && data.length > 0 ? (
        <div className="mt-5 print:hidden">
          <table className="table w-11/12 mx-auto my-10">
            <thead>
              <tr>
                <th>No</th>
                <th>Check No</th>
                <th>Employee</th>
                <th>Picked up by</th>
                <th>Amount</th>
                <th>Cashier</th>
                <th>Updated</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{item.check_num}</td>
                  <td>{item.employee}</td>
                  <td>{item.picked_up_by}</td>
                  <td>{item.net || "0.00"}</td>
                  <td>{item.transaction?.cashier?.name}</td>
                  <td>
                    <Moment format="MM/DD/YYYY hh:mm a">
                      {item.transaction?.updated_at}
                    </Moment>
                  </td>
                  <td className="flex space-x-2">
                    {item.status === "completed" && (
                      <>
                        <i
                          className="fa-solid fa-edit text-yellow-600 cursor-pointer hover:scale-105 active:scale-95"
                          onClick={() => openModal(item)}
                        />
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer hover:scale-105 active:scale-95"
                          onClick={() => removeTransaction(item)}
                        />
                      </>
                    )}
                    {item.status === "closed" && (
                      <i
                        className="fa-solid fa-eye text-blue-300 hover:text-blue-500 cursor-pointer"
                        onClick={() => handleData(item)}
                      />
                    )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="bg-green-100" colSpan={4}>
                  Total:
                </td>
                <td className="bg-green-100 font-bold" colSpan={4}>
                  {total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="print:hidden">
          <Info state={true} message="There is not transactions" />
        </div>
      )}
      {data && total && <ToPrint data={data} total={total} rows={rows} />}
      <Printing />
      {modal && (
        <EditTransaction
          modal={modal}
          item={item}
          close={() => setModal(false)}
        />
      )}
      {modal2 && (
        <Details
          details={data2}
          modal={modal2}
          close={() => setModal2(false)}
        />
      )}
    </div>
  );
}
