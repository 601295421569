import Menu from './menu';

export default function Header() {
    return (
        <div className='w-full flex justify-between items-center p-2 print:hidden'>
            <div className='w-64'>
                <img src={require('../../../assets/img/SmartCash_Logo_HD.png')} alt="" />
            </div>
            <Menu />
        </div> 
    )
}