import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import List from "./list";
import Form from "./form";
import EmptyForm from "./form-empty";

export default function Results({ checkData, withdrawalModal }) {
  const check = useSelector((state) => state.check);
  const [data, setData] = useState();

  useEffect(() => {
    setData(check.checks);
  }, [check.checks]);

  // console.log(check.checks)

  return (
    <>
      {check.checks && check.checks.length === 0 && (
        <EmptyForm checkData={checkData} withdrawalModal={withdrawalModal} />
      )}
      {check.checks && check.checks.length > 1 && <List />}
      {check.checks && check.checks.length === 1 && (
        <Form withdrawalModal={withdrawalModal} />
      )}
    </>
  );
}
