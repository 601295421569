import api from "../../utils/api";
import { btnLoading, error, modal, success, loading } from "../reducers/r_main";
import { loaded } from "../reducers/r_config";
import {
  completed,
  receiptPrint,
  printingDone,
  // withdrawal,
  errorTran,
  closed,
  completedLoaded,
  searchRedu,
  history,
  filterParam,
  filterDetais
} from "../reducers/r_transaction";
import { cashierDetailsLoaded } from "../reducers/r_checks";

export const userToMain = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    await api.post("transaction/to-main", data);
    dispatch(loaded());
    dispatch(modal());
    dispatch(
      success({
        status: true,
      })
    );
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const mainToCashier = (data) => async (dispatch) => {
  // console.log('Receiving: ',data)
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    await api.post("transaction/to-cashier", data);
    dispatch(loaded());
    dispatch(modal());
    dispatch(
      success({
        status: true,
      })
    );
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

// Deposit
export const mainToCashierFromChecks = (data, close) => async (dispatch) => {
  // console.log('Receiving: ',data)
  dispatch(btnLoading(true));
  dispatch(error());
  try {
    await api.post("transaction/to-cashier", data);
    dispatch(loaded());
    dispatch(cashierDetailsLoaded());
    close();
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const getCompleted = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/completed-report");
    dispatch(completed(response.data));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(loading());
  }
};

export const getClosed = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/closed-report");
    dispatch(closed(response.data));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(loading());
  }
};

export const print = (data) => async (dispatch) => {
  try {
    await api.post("/print-report", { data: data });
    dispatch(receiptPrint(true));
    setTimeout(() => {
      try {
        window.print();
        // window.print();
      } catch (err) {
      } finally {
        dispatch(printingDone(true));
      }
    }, [1000]);
  } catch (err) {
    console.log(err);
  }
};

export const createWithdrawal =
  (data, handleError, handleSuccess) => async (dispatch) => {
    dispatch(btnLoading(true));
    try {
      const response = await api.post("/withdrawal", data);
      if (response.status === 200) {
        handleSuccess();
        try {
          window.print();
          setTimeout( () => window.print(), 2000)
        } catch (err) {
        } finally {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (err) {
      handleError();
      dispatch(
        errorTran({
          status: true,
          message: err.response.data.message,
          errors: err.response.data.errors,
        })
      );
    } finally {
      dispatch(btnLoading(false));
    }
  };

export const updateTransaction = (data, close) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.put("/update-during-complete", data);
    if (response.status === 200) {
      dispatch(loaded());
      close();
      dispatch(completedLoaded());
    }
  } catch (err) {
    console.err(err);
  } finally {
    dispatch(btnLoading(false));
  }
};

export const search = (data) => async (dispatch) => {
  try {
    const response = await api.get(`/search/${data.search}`);
    if (response.status === 200 && response.data.length > 0) {
      dispatch(searchRedu(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeTransactionAction = (item) => async (dispatch) => {
  try {
    // console.log(item)
    const response = await api.post(`/remove-transaction`, item);
    // console.log(response.data);
    if (response.status === 200) {
      // dispatch(completedLoaded());
      dispatch(completed(response.data));
      dispatch(cashierDetailsLoaded());
    }
  } catch (err) {
    console.log(err);
  }
};

export const cashierToCashier = (data, close) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.post("/transaction/transfer", data);
    if (response.status === 200) {
      window.location.reload();
    }
  } catch (err) {
    dispatch(
      errorTran({
        status: true,
        message: err.response.data.message,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading(false));
  }
};

export const getDetails = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.get(
      `/totals/${data.cashier_id}/${data.start_date}/${data.end_date}`
    );
    if (response.status == 200) {
      dispatch(history(response.data));
    }
  } catch (err) {
    dispatch(console.log(`getDetails: ${err}`));
  } finally {
    dispatch(btnLoading(false));
  }
};

export const filterValue = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.post('/filter', data);
    if (response.status === 200) {
      dispatch(filterParam(response.data));
    }
  } catch {

  } finally {
    dispatch(btnLoading(false));
  }
}

export const filterDetails = (check_id) => async (dispatch) => {
  try {
    const response = await api.get(`/${check_id}/filter-transaction`);
    dispatch(filterDetais(response.data[0]));
  } catch (err) {
    console.log("Error: ", err);
  } finally {

  }
}
