import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { filterCheck } from "../../state/reducers/r_checks";

export default function List() {
  const dispatch = useDispatch();
  const check = useSelector((state) => state.check);

  return (
    <table className="table mt-10">
      <thead>
        <tr>
          <th colSpan={6} className="p-10">
            There are{" "}
            <span>
              {check.checks.length} records. Please select one of them to
              continue.
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {check.checks.map((item, key) => (
          <tr key={key} className="hover:bg-blue-100">
            <td>{item.employee}</td>
            <td>{item.ssn}</td>
            <td>${item.net}</td>
            <td>
              <Moment fromNow>{item.updated_at}</Moment>
            </td>
            <td>
              <i
                className="fa-solid fa-angles-right cursor-pointer hover:scale-105 active:scale-95"
                onClick={() => dispatch(filterCheck(item.id))}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
