import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
	name: 'role',
	initialState: {
		roles: [],
		loaded: false,
		roleLoading: false,
		permissions: []
	},
	reducers: {
		roles: (state, action) => {
			state.roles = action.payload;
			state.loaded = true;
		},
		loaded: (state, action) => {
			state.loaded = action.payload ? action.payload : false
		},
		roleLoading: (state, action) => {
			state.roleLoading = action.payload ? action.payload : false;
		},
		permissions: (state,action) => {
			state.permissions = action.payload
		}
	}
})

export const { roles, loaded, roleLoading, permissions } = roleSlice.actions;
export default roleSlice.reducer;